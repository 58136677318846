import { createAction, props } from '@ngrx/store';

export const APP_SETTINGS_INIT = createAction(
    '[HomeComponent] APP_SETTINGS_INIT',
    props<{ countryCode: number, languageId: string, ctaLanguageId: string, arePricesAvailable: boolean, showPrices: boolean, ephemeralDeviceId: string, isScreenCastActive: boolean, streamingAvailable: boolean, protocol: string, host: string, hostName:string  }>()
)

export const APP_SETTINGS_SAVE_CLIENT_NAME = createAction(
    '[HomeComponent] APP_SETTINGS_SAVE_CLIENT_NAME',
    props<{ clientName: string, clientSurname: string }>()
)

export const APP_SETTINGS_SWITCH_COUNTRY = createAction(
    '[HomeComponent] APP_SETTINGS_SWITCH_COUNTRY',
    props<{countryCode: number, languageId: string, ctaLanguageId: string, showPrices: boolean, arePricesAvailable: boolean}>()
)

export const APP_SETTINGS_SWITCH_LANGUAGE = createAction(
    '[HomeComponent] APP_SETTINGS_SWITCH_LANGUAGE',
    props<{languageId: string}>()
)
export const APP_SETTINGS_SWITCH_CTA_LANGUAGE = createAction(
    '[HomeComponent] APP_SETTINGS_SWITCH_CTA_LANGUAGE',
    props<{ctaLanguageId: string}>()
)

export const APP_SETTINGS_SET_COUNTRY = createAction(
    '[Authentication Service] APP_SETTINGS_SET_COUNTRY',
    props<{countryCode: number, languageId: string, ctaLanguageId: string}>()
)

export const APP_SETTINGS_TOGGLE_PRICES = createAction(
    '[HomeComponent] APP_SETTINGS_TOGGLE_PRICES',
    props<{ showPrices: boolean }>()
)

export const APP_SETTINGS_SET_PRICES_AVAILABLE = createAction(
    '[ModelsLoaded] APP_SETTINGS_SET_PRICES_AVAILABLE',
    props<{ arePricesAvailable: boolean }>()
)

export const APP_SETTINGS_CONFIGURATION_SAVED = createAction(
    'APP_SETTINGS_CONFIGURATION_SAVED',
    props<{ configId: string }>()
)

export const APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID = createAction(
    'APP_SETTINGS_SET_EPHEMERAL_DEVICE_ID',
    props<{ ephemeralDeviceId: string }>()
)

export const APP_SETTINGS_SET_SCREENCAST_ACTIVE = createAction(
    'APP_SETTINGS_SET_SCREENCAST_ACTIVE',
    props<{ isScreenCastActive: boolean }>()
)
export const APP_SETTINGS_SET_ENV_NAME = createAction(
    'APP_SETTINGS_SET_ENV_NAME',
    props<{envName: string}>()
)
export const APP_SETTINGS_SET_STREAMING_STATUS = createAction(
    'APP_SETTINGS_SET_STREAMING_STATUS',
    props<{ streamingAvailable: boolean }>()
)
export const APP_SETTINGS_SET_APP_EXECUTION_INFORMATIONS = createAction(
    'APP_SETTINGS_SET_APP_EXECUTION_INFORMATIONS',
    props<{ protocol: string, host: string, hostName: string }>()
)
export const APP_SETTINGS_SET_APPLICATION_STEP = createAction(
    'APP_SETTINGS_SET_APPLICATION_STEP',
    props<{ applicationStep: string}>()
)