import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';
import smoothscroll from 'smoothscroll-polyfill';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { CAR_DETAILS_ENUM, CTA_KEYS_ENUM, IDomElementData, IMAGE_TYPE_ENUM, MENU_ENUM, OPTION_TYPE_ENUM, PAIRING_STEP_ENUM } from 'src/app/core/models/common.model';
import { CarModel, Family, TechData } from 'src/app/core/models/get-models-service.model';
import { Layer, Menu, Optional, Tab } from 'src/app/core/models/interface-service.model';
import { GetPrintSendRequest } from 'src/app/core/models/print-send.model';
import { GetSaveConfigurationRequest } from 'src/app/core/models/save-conf-service.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { AppSettingsActions } from 'src/app/core/store/actions/app-settings/app-settings-exported-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import { ICTA } from '../../core/models/country-prop-service.model';
import { Container } from '@angular/compiler/src/i18n/i18n_ast';
import { environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { RESET_LAST_MODEL, RESET_SELECTED_MODEL } from 'src/app/core/store/actions/models/models-actions';

declare var $: any;

@Component({
  selector: 'app-summary-configuration',
  templateUrl: './summary-configuration.component.html',
  styleUrls: ['./summary-configuration.component.scss'],
  animations: [trigger('fade', [ state('visible', style({ opacity: 1 })), state('hidden', style({ opacity: 0 })), transition('visible <=> hidden', animate('1s ease-in-out')) ]) ]
})
export class SummaryConfigurationComponent {

  @ViewChild('summarysidebar') sidebarEl: ElementRef<HTMLElement>;
  @Output() closeModalEvent = new EventEmitter()
  @Output() onToggleAccordion = new EventEmitter()
  @Output() onScrollPosition = new EventEmitter()
  @Output() openSaveModalEvent = new EventEmitter()
  @Output() openTridentModal = new EventEmitter();
  @Output() showCinematicEventFromSummary : EventEmitter<[boolean,boolean]> = new EventEmitter;
  @Input() currentCarModel: CarModel
  @Input() countryCode: number
  @Input() languageId: string
  @Input() optionsForAnalytics: string;
  @Input() configId: string;
  @Input() dealerId: string;
  @Input() options: UIOptItem[]
  @Input() menuItems: Menu[];
  @Input() ctas: ICTA[];
  @Input() hiddenOptions: UIOptItem[];
  @Input() priceStatus: boolean;
  @Input() config: string[];
  @Input() optionalsBinding: Optional[];
  @Input() imageView: string;
  @Input() imageLayers: Layer[];
  @Input() isMainScreen: boolean;
  @Input() customerName: string;
  @Input() customerSurname: string = '';
  @Input() showCustomerName: boolean = false;
  @Input() totalPrice: string;
  @Input() unformattedBasePrice: any;
  @Input() unformattedTaxes: number;
  @Input() unformattedEquipments: number;
  @Input() accordionToggled: {accordionToggled: string, value: boolean};
  @Input() set scrollPosition(scrollPosition: string) {
    this.setScrollPosition(scrollPosition)
  }
  @Input() screenCastActive: boolean
  @Input() ephemeralDeviceID: string
  @Input() defaultOptions: UIOptItem[]
  @Input() set packages(_packs : Pack[]){
    if(_packs){
      this.packs = _packs
    }
  }
  @Input() showCinematicFromWrapper : boolean
  @Input() streamingAvailable : boolean
  @Input() isOnCloud: boolean
  @Input() isOnPremise: boolean
  @Input() isCinematicActiveForSelectedTrim : boolean
  @Input() idleTimeIsExpired : boolean

  isModalSendPdfOpen: boolean = false;

  iframe = ViewChild('iframe')

  packs : Pack[];
  selectedOptions: UIOptItem[] = [];
  summaryVisible : boolean;
  groupsList: string[];
  carModelCode: string;
  modelName: string;
  generalPrice: string;
  engineLayout: string;
  engineSize: string;
  maxPower: string;
  maxSpeed: string;
  popupShow: boolean = false;
  familyCommercialCode: string;
  currency: string;
  priceMask: string;
  commercialName: string;
  taxes: string;
  equipments: any;
  parsedTechData: TechData[];
  parsedEmissionsData: TechData[];
  parsedDimensionsData: TechData[];
  parsedConsumptionsData: TechData[];
  carsCounter: string
  //minPrice: string
  accordionOpened = [];
  //#region CTAs' modals
  searchNewInventoryEndpoint: SafeResourceUrl;
  showSearchNewInvetoryCTA = false
  showGenericInfoCTA: boolean;
  genericInfoEndpoint: SafeResourceUrl;
  showGenericInfoModal = false
  sendPdfCtaEnabled = false
  showSendPdfModal = false
  showSaveModal = false
  sendPdfBasePath: string;
  saveCtaEnabled: boolean;
  saveFrameLink: string;
  isLoading = false
  showTaxes: boolean = false;
  showCinematicFromSummary = false;
  //#endregion

  labels: any
  families: Family[] = [];
  splittedCommercialName: string[];
  showRestartConfirmationModal: boolean;

  wheelNormLink : string;
  showWheelNormCta : boolean;
  showWLtpRangeConsumption: boolean

  ephemeralDeviceID$: Subscription;
  screenCastActive$: Subscription;
  labels$: Subscription;
  families$: Subscription;

  private scrollSub$: Subscription;
  private elementsData: IDomElementData[] = []
  private firstElementTop: number;

  constructor(
    private carConfigurationService: CarConfigurationService,
    private store: Store<MxeReducers>,
    private sanitizer: DomSanitizer,
    private uiCommonService: UiCommonService,
    private maseratiService: MaseratiService,
    private chg: ChangeDetectorRef,
    private mxeAnalyticsService: MxeAnalyticsService,
    private autobahnClient: AutobahnClient,
    private router: Router
  ) { }

  ngOnInit() {
    if(this.isMainScreen) {
      // Calculate viewport height
      let summaryWrapper: HTMLElement = document.getElementById('summary-wrapper') as HTMLElement
      if(summaryWrapper)
        summaryWrapper.style.height = `${window.innerHeight}px`
  
      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
  
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
      )

      this.labels$ = this.store.select(s => s.countryPropState.labels).subscribe(
        labels => this.labels = labels
      )

      this.families$ = this.store.select(s => s.modelsState.families).subscribe(
        families => this.families = families
      )

      const extColor = this.carConfigurationService.getExteriorColor()
      if (extColor !== '') {
        const searchNewInventoryCta = this.ctas.find(c => c.name === CTA_KEYS_ENUM.SEARCH_NEW_INVENTORY)
        if (searchNewInventoryCta && searchNewInventoryCta.link != '') {
          this.showSearchNewInvetoryCTA = true
          this.searchNewInventoryEndpoint = this.sanitizer.bypassSecurityTrustResourceUrl(`${searchNewInventoryCta.link}?configId=${this.configId}&dealerCodeList=${this.dealerId}&iframe=true`)
          console.debug(`Search new inventory link: ${this.searchNewInventoryEndpoint}`)
        }
        const searchInventoryCount = this.ctas.find(c => c.name === CTA_KEYS_ENUM.SEARCH_NEW_INVENTORY_COUNT)
        if (searchInventoryCount && searchInventoryCount.link != '') {
          const params = `&dealerCodeList=${this.dealerId}&configExtColor=${extColor}`
          this.maseratiService.preflightNoHeaders(searchInventoryCount.link, params).subscribe({
            next: response => {
              if (response && response.result) {
                this.carsCounter = response.result['total']
                this.chg.detectChanges();
                //this.minPrice = this.formatPrice(response.result['minPrice'])
              } else {
                console.error(response.errorMessage)
              }
            },
            error: (error) => {
              if(!environment.production){
                console.error(error)
              }
            },
            complete: () => console.info('SearchInventoryCount completed')
          }
          )
        }
        const genericInfoCTA = this.ctas.find(c => c.name === CTA_KEYS_ENUM.GENERIC_INFO_CTA)
        if (genericInfoCTA && genericInfoCTA.link != '' && genericInfoCTA.enabled) {
          this.showGenericInfoCTA = true
          this.genericInfoEndpoint = this.sanitizer.bypassSecurityTrustResourceUrl(genericInfoCTA.link)
          console.debug(`WTL link: ${this.genericInfoEndpoint}`)
        }
        const sendPdfCTA = this.ctas.find(c => c.name === CTA_KEYS_ENUM.PDF)
        if (sendPdfCTA && sendPdfCTA.link != '' && sendPdfCTA.enabled) {
          this.sendPdfCtaEnabled = true
          this.sendPdfBasePath = sendPdfCTA.link
          console.debug(sendPdfCTA.link)
        }
        const saveCTA = this.ctas.find(c => c.name === CTA_KEYS_ENUM.MXE_SAVE_CTA_WTL)
        if (saveCTA && saveCTA.link != '' && saveCTA.enabled) {
          this.saveCtaEnabled = true
          this.saveFrameLink = saveCTA.link
        }
        const wheelnormCta = this.ctas.find(c => c.name === CTA_KEYS_ENUM.WHEELNORM_CTA)
        if(wheelnormCta && wheelnormCta.link != '' && wheelnormCta.enabled){
          this.showWheelNormCta = true;
          this.wheelNormLink = wheelnormCta.link
        }
        const wltpRangeCta = this.ctas.find(c => c.name == CTA_KEYS_ENUM.WLTP_RANGE_CTA)
        if(wltpRangeCta && wltpRangeCta.enabled){
          this.showWLtpRangeConsumption = true;
        }
    }
    }

    this.menuItems = this.filterMenuItems();
    this.resizeRightAndLeftBar();
    this.setScrollForCtasDivContainer();
    this.getSelectedOptions(this.options)
    this.groupsList = [...new Set(this.selectedOptions.map(o => o.group))]
    this.currency = this.currentCarModel.currency
    this.priceMask = this.currentCarModel.priceMask
    this.carModelCode = this.currentCarModel.modelCode
    this.modelName = this.currentCarModel?.modelName
    this.familyCommercialCode = this.currentCarModel?.familyCommercialCode
    this.commercialName = this.currentCarModel?.commercialName
    this.summarySpecificationsData(this.currentCarModel!.techData)
    this.totalPrice = this.totalPrice.replace('-', '')
    this.showTaxes = this.unformattedTaxes > 0
    if(this.unformattedTaxes){
      this.taxes = this.formatPrice(this.unformattedTaxes?.toString())
    }
    if(this.unformattedEquipments){
      this.equipments = this.formatPrice(this.unformattedEquipments?.toString())
    }
    this.splitCommercialName();
    this.summaryVisible = true;
    this.showCinematicFromSummary = false;   
     
    $(this.iframe).on("load", () => {
      console.log('iframe loaded');

      let head = $("#myiFrame").contents().find("head");
      let css = `.onetrust-pc-dark-filter.ot-fade-in {display: none!important;}div#onetrust-banner-sdk {display: none!important;}`;
      $(head).append(css);
    });

    this.initAdobeTracker()
    smoothscroll.polyfill();
  }

  ngAfterViewInit() {
    if(this.isMainScreen) {
      let summarySidebar = document.getElementById('summary-sidebar') as HTMLElement
      const absoluteActionHeight = (document.getElementsByClassName('mxe__summary-configuration--summary-sidebar--absolute-actions')[0] as HTMLElement).offsetHeight
      summarySidebar.style.height = `${window.innerHeight - 112 - absoluteActionHeight}px`
    }

    this.setSectionsData();
    this.scrollSub$ = fromEvent(this.sidebarEl.nativeElement, 'scroll').subscribe(() => {
      if(this.isMainScreen)
      this.onScroll()
    })
  }

  async downloadTyreRegulationPdf() {
    let adm;
    this.store.select(s => s.carGridState.carGrid.general.version).subscribe(
        vers => adm = vers
    );

    var params = {
        adm: adm,
        onlyCheckPdfDownload: "1",
        config: this.carConfigurationService.serializeCarConfiguration(),
        callback: 'zNPCallback'
    }

    const queryStringParams = new URLSearchParams(params).toString();
    const url = `${this.wheelNormLink}&${queryStringParams}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
        });

        if (response.ok) {
            const text = await response.text();

            const cleanedText = text.replace(/^zNPCallback\(/, '').replace(/\)$/g, '');

            try {
                const responseJson = JSON.parse(cleanedText);

                if (responseJson && responseJson.errorCode && responseJson.errorCode == '0') {
                    params.onlyCheckPdfDownload = "0";
                    const queryString = new URLSearchParams(params).toString();
                    const pdfLink = `${this.wheelNormLink}&${queryString}`;
                    if (pdfLink) {
                        window.open(pdfLink);
                    }
                } else if (responseJson && responseJson.errorCode && responseJson.errorCode != '0') {
                  if(!environment.production){
                    console.log(`Error in tyres regulation request: errorCode: ${responseJson.errorCode}, errorMessage: ${responseJson.errorMessage}`)
                  }
                }
            } catch (error) {
              if(!environment.production){
                console.error('Error parsing JSON from response:',error);
              }else{
                console.error('Error parsing JSON from response:');
              }
               
            }
        } else {
            throw new Error(`Something went wrong in tyre regulation service call: ${response.status}`);
        }
    } catch (error) {
        if(!environment.production){
          console.error('Error in tyre regulation call service:', error);
        } else{
          console.error('Error in tyre regulation call service:');
        }
        
    }
}


  isAccordionVisible(menuTabs: Tab[]): boolean {
    return menuTabs?.filter(m => this.groupsList.includes(m.id)).length > 0
  }

  getAccordionHeight(meunuTabs : Tab[]) : string{
    const itemHeight = this.isMainScreen ? 100 : 178
    let countItemInsideSection:number = 0;
    meunuTabs.forEach( tab =>{
      var optarray = this.getOptionsInGroup(tab.id);
      if(optarray.length>0){
        optarray.forEach(opt =>{
          if(opt.id && opt.id != ""){
            countItemInsideSection++;
          }
        })
      }
    })
    return `${countItemInsideSection * itemHeight}px`;
  }


  closeSummary(){
    this.summaryVisible = false;
    setTimeout(
       () => {
        this.closeModalEvent.emit()
       },500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.isMainScreen){
      if(changes['accordionToggled'] && this.accordionToggled) {
        const {accordionToggled, value} = this.accordionToggled
        this.accordionOpened[accordionToggled] = value
        this.setSectionsData();
      }
    }
    if(changes && changes['idleTimeIsExpired'] && changes['idleTimeIsExpired'].currentValue && this.summaryVisible && this.isCinematicActiveForSelectedTrim){
      //Called like screenSaver --> ShowCinematicFromSummary,bScreenSaver
      this.showCinematicEventFromSummary.emit([true,true])
    }
    if(changes && changes['showCinematicFromWrapper'] && !changes['showCinematicFromWrapper'].currentValue){
      this.showCinematicFromSummary = false;
    }
  }

  resizeRightAndLeftBar(){
    var actualViewport = window.innerWidth;
    var widthRightBar = actualViewport * (0.6);
    $("#summary-sidebar").css("width", widthRightBar);
    var widthLeftBar = actualViewport - widthRightBar;
    $("#left-summary-bar").css("width", widthLeftBar);
  } 

  setScrollForCtasDivContainer(){
    var actualViewport = window.innerWidth;
    var actualVh = window.innerHeight;
    if(actualViewport <= 1024){
      $("#cta-container").css("height", "auto");
    }
    if(actualViewport > 1194 && actualViewport <= 1366){
       $("#cta-container").css("height", 360);
    }
    if(actualVh <= 768){
      $("#cta-container").css("height", 162);
    }
  } 

  onScroll(){
    const scrollPosition = this.sidebarEl.nativeElement.scrollTop
    const selectedElement: IDomElementData | undefined = this.getSelectedElement(scrollPosition)
    if (selectedElement){
      this.chg.detectChanges()
    }
    this.onScrollPosition.emit(selectedElement?.id)
  }

  private getSelectedElement(scrollPosition: number) {
    if(scrollPosition >= this.firstElementTop) {
      return this.elementsData.find(el => scrollPosition >= el.offTop && scrollPosition < (el.offTop + el.offHeight) && !el.id?.startsWith('sec'))
    } else {
      return this.elementsData.find(e => e.offTop === this.firstElementTop)
    }
  }

  tabIsVisible(tabId: string): boolean {
    return this.options.filter(o => o.group === tabId).length > 0
  }

  private splitCommercialName(){
    this.splittedCommercialName = this.commercialName.trim().split(/\s+/);
  }

  private setSectionsData() {
    this.elementsData = []
    this.menuItems.forEach(
      m => {
        const domOpt = this.uiCommonService.toElementData(m.id)
        if(domOpt)
          this.elementsData.push(domOpt)
      }
    )
    this.groupsList?.forEach(
      group => {
        const groupOptions  = this.getOptionsInGroup(group)
        groupOptions.forEach(g => {
          const domItem = this.uiCommonService.toElementData(`summary_${g.id}`)
          if(domItem)
            this.elementsData.push(domItem)
        })
      }
    )
    const ids: string[] = ['SUMMARY_CONFIGURATION_RECAP','TECH_SPECS','DIMENSIONS_AND_WEIGHT','WLTP_CTA_LABEL']
    ids.forEach(id => {
      const domOpt = this.uiCommonService.toElementData(id)
      if(domOpt)
        this.elementsData.push(domOpt)
    })

    this.firstElementTop = this.elementsData.map(e => e.offTop).sort((a, b) => a - b)[0]
  }

  ngOnDestroy() {
    this.scrollSub$.unsubscribe()
    if(this.isMainScreen){
      this.ephemeralDeviceID$.unsubscribe()
      this.screenCastActive$.unsubscribe()
      this.labels$.unsubscribe()
      this.families$.unsubscribe()
    }
  }

  private getSelectedOptions(options: UIOptItem[]) {
    options.filter(o =>
      (o.status.selected === true
        || o.status.selectedInPack)
      && o.type === OPTION_TYPE_ENUM.OPTION
    )
      .forEach(opt => {
        if(opt.colorList && opt.colorList.length > 0) {
          let color = opt.colorList.find(c => c.selected === true)
          if(color){
            if(color.fuoriserie === opt.fuoriserie) {
              let filteredOpt: UIOptItem = {
                id: opt.id,
                colorList: [color],
                fuoriserie: opt.fuoriserie,
                highlight: opt.highlight,
                group: opt.group,
                layerGroup: opt.layerGroup,
                onlyImplicit: opt.onlyImplicit,
                price: opt.price,
                quantity: opt.quantity,
                section: opt.section,
                status: opt.status,
                type: opt.type,
                must: opt.must
              }
              this.selectedOptions.push(filteredOpt)
            }
          }
        } else {
          let filteredOpt: UIOptItem = {
            id: opt.id,
            colorList: [],
            fuoriserie: opt.fuoriserie,
            highlight: opt.highlight,
            group: opt.group,
            layerGroup: opt.layerGroup,
            onlyImplicit: opt.onlyImplicit,
            price: opt.price,
            quantity: opt.quantity,
            section: opt.section,
            status: opt.status,
            type: opt.type,
            must: opt.must
          }
          this.selectedOptions.push(filteredOpt)
        }
      })
  }

  private setScrollPosition(scrollPosition: string) {
    if(this.sidebarEl) {
      const el = this.sidebarEl.nativeElement as HTMLElement;
      const scrollTo = this.elementsData.find(e => e.id == scrollPosition)
      if(scrollTo){
        el.scrollTo({ top: scrollTo.offTop, behavior: 'smooth' });
        this.chg.detectChanges()
      }
    }
  }

  summarySpecificationsData(data: TechData[]) {
    this.parsedTechData = this.createTechData(data);
    this.parsedDimensionsData = this.createDimensionAndWeightData(data);
    this.parsedEmissionsData = this.createEmissionsData(data)
    this.parsedConsumptionsData = this.createConsumptionsData(data)
    this.engineSize = this.parsedTechData && this.parsedTechData.find(x => x.key == 'engineSize') ? this.parsedTechData.find(x => x.key == 'engineSize')!.value : ''
    this.engineLayout = this.parsedTechData && this.parsedTechData.find(x => x.key == 'engineLayout') ? this.parsedTechData.find(x => x.key == 'engineLayout')!.value : ''
    this.maxPower = this.parsedTechData && this.parsedTechData.find(x => x.key == 'maxPower') ? this.parsedTechData.find(x => x.key == 'maxPower')!.value : ''
    this.maxSpeed = this.parsedTechData && this.parsedTechData.find(x => x.key == 'maxSpeed') ? this.parsedTechData.find(x => x.key == 'maxSpeed')!.value : ''
  }

  getLabel(optId: string, type = '', color = '') {
    return this.uiCommonService.getLabel(optId, type, color, this.currentCarModel.modelCode)
  }

  /**
   * Returns a normalized string for analytics
   */
  normalizeStringForAnalytics(value: string) {
    return this.mxeAnalyticsService.normalizeStringForAnalytics(value)
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
  
  getImagePath(group: string, optId: string, color = '', menuItem = ''): string {
    const type = (menuItem === MENU_ENUM.OPT || menuItem === MENU_ENUM.PACK || menuItem === MENU_ENUM.ACC) ? IMAGE_TYPE_ENUM.INFO_1400_875 : IMAGE_TYPE_ENUM.MENU_ICON 
    return this.carConfigurationService.getImagePath(this.carModelCode, group, optId, type, color, this.familyCommercialCode)
  }

  isAccordionExpanded(accordionId: string): boolean{
    if(!(accordionId in this.accordionOpened)){
      this.accordionOpened[accordionId] = true
      return true
    }
    return this.accordionOpened[accordionId]
  }

  toggleAccordion(dataaccordion: string, e): void {
    e.stopPropagation && e.stopPropagation()
    if(this.accordionOpened[dataaccordion]){
      this.accordionOpened[dataaccordion] = false
    } else {
      this.accordionOpened[dataaccordion] = true
    }
    this.onToggleAccordion.emit({accordionToggled :dataaccordion, value: this.accordionOpened[dataaccordion]});
  }

  openSaveModal() {
    this.openSaveModalEvent.emit()
  }

  public openCinematic(){
    this.showCinematicFromSummary = !this.showCinematicFromSummary;
    //ShowCinematicFromSummary,bScreenSaver
    this.showCinematicEventFromSummary.emit([this.showCinematicFromSummary,false])
  }

  openSendModal(value: boolean = true) {
    this.isModalSendPdfOpen = value;
  }

  sendModalStatusController(modalStatus: boolean) {
    this.isModalSendPdfOpen = modalStatus;
  }

  formatPrice(price: string): string {
    return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
  }

  private filterMenuItems(): Menu[] {
		let excludedIds: string[] = ['MENU_LINES']
		this.menuItems.forEach(item => {
			if(!this.haveSectionsVisible(item)) {
				excludedIds.push(item.id)
			}
		})
		return this.menuItems.filter(m => !excludedIds.includes(m.id))
	}

	 haveSectionsVisible(menuItem: Menu): boolean {
		let ret: boolean = false
		const menuTabs: Tab[] | undefined = menuItem?.tabs   
		menuTabs?.map(x => x.id).forEach(tabId => {
		  if(this.options.filter(o => o.group === tabId).length > 0) {
			ret = true
		  }
		})
		return ret;
	}


  /**
   * Gets all selected options for a given group
   * @param sectionId
   */
  getOptionsInGroup(groupId: string): UIOptItem[] {
    let groupOptions: UIOptItem[] = []
    let items = this.selectedOptions.filter(o => o.group === groupId)
    this.menuItems.forEach(menu => {
      const tab = menu.tabs.find(g => g.id === groupId)
      if (tab) {
        tab.sections?.forEach(section => {
          items.filter(i => i.section === section.id).forEach(opt => {
            if (opt.colorList.length > 0) {
              let color = opt.colorList.find(c => c.selected)
              if (
                section.filter_fuoriserie == null ||
                (color?.fuoriserie && section.filter_fuoriserie == '1') ||
                (!color?.fuoriserie && section.filter_fuoriserie == '0')
              ) {
                groupOptions.push(opt)
              }
            } else {
              if (opt.status.colorSelected == null) {
                if (
                  section.filter_fuoriserie == null ||
                  (opt.fuoriserie && section.filter_fuoriserie == '1') ||
                  (!opt.fuoriserie && section.filter_fuoriserie == '0')
                ) {
                  groupOptions.push(opt)
                }
              }
            }
          })
        })
      }
    })
    return groupOptions
  }

  /**
   * Temporary solution used to print pdf as is
   */
  async printPdf() {
    this.isLoading = true
    if(!this.configId){
      //call save to create configId
      this.callSaveService().then(
        (confId) => {
          this.configId = confId
          this.printSend()
        }
      )
    } else {
      this.printSend()
    }
  }

  isPriceValid(price: string): boolean {
    return this.uiCommonService.isPriceValid(price)
  }

  private printSend() {
    const payload: GetPrintSendRequest = {
      country: `${this.countryCode}`,
      lang: this.languageId,
      configId: this.configId,
      type: 'pdf',
      model: this.currentCarModel.modelCode,
    };

    let pdfWindowReference = window.open('about:blank', "_blank")
    let fileUrlString: string;
    this.maseratiService.printSend(this.sendPdfBasePath, payload).subscribe({
      next: (value: Blob) => {
        const blob = new Blob([value], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        fileUrlString = fileURL
        console.debug('Downloading the pdf from: ', fileURL);
      },
      complete: () => {
        this.isLoading = false
        pdfWindowReference!.location = fileUrlString
        this.chg.detectChanges()
        console.info('[MASERATI SERVICE] printSend call completed');
      },
      error: (e) => {
        if(!environment.production){
          console.error('An error occurred while creating the pdf file...', e)
        } else{
          console.error('An error occurred while creating the pdf file...')
        }
      },
    });
  }

  /**
   * Getting car image from photocopier
   */
  getCarImage(): string {
    const configItems = this.selectedOptions
      .concat(this.hiddenOptions)
      .concat(this.defaultOptions)
    const uniqueConfigItems = [...new Set(configItems)]
    let path = `${environment.photocopier_endpoint}/${this.carModelCode}/533/c300/gfx${this.imageView}.png?config=background;shadow;${this.uiCommonService.buildConfigForPhotocopier(this.imageLayers, uniqueConfigItems, this.optionalsBinding, this.packs)}`
    const familyWithCorrectShadow: string[] = ['gr', 'gh', 'lv', 'qp'] 
    if(!familyWithCorrectShadow.includes(this.currentCarModel.familyCommercialCode.toLowerCase())){
      path = path.replace('shadow', 'shadow_app')
    }
    return path
  }

  private async callSaveService(): Promise<string> {
    return new Promise((resolve, reject) => {
      const payload: GetSaveConfigurationRequest = {
        country: this.countryCode,
        modelCode: +this.carModelCode,
        config: this.carConfigurationService.serializeCarConfiguration(),
        toFreeze: true,
        emailAddress: "",
        sendEmail: "sendToMe",
        firstName: ""
      };
      this.maseratiService.saveCarConfig(payload).subscribe({
        next: (value) => {
            if (value.errorCode > 0) {
              console.error(value.errorMessage);
              throw value
            }
            else {
              this.store.dispatch(AppSettingsActions.APP_SETTINGS_CONFIGURATION_SAVED({configId: value.result.configId}))
              resolve(value.result.configId)
            }
        },
        complete: () =>
        {
          console.info('[MASERATI SERVICE] saveCarConfig call completed')
        },
        error: (e) =>
        {
          if(!environment.production){
            console.error(e)
          }
          reject(e)
        }
      });
    })
  }

  private createTechData(techData: TechData[]): TechData[] {
    let keyFilter: string[] = [
      CAR_DETAILS_ENUM.ACCELERATION,
      CAR_DETAILS_ENUM.MAX_SPEED,
      CAR_DETAILS_ENUM.MAX_TORQUE,
      CAR_DETAILS_ENUM.MAX_POWER,
      CAR_DETAILS_ENUM.ENGINE_SIZE,
      CAR_DETAILS_ENUM.ENGINE_LAYOUT,
    ];
    const techDataFilterdState = techData?.filter((t) => keyFilter.includes(t.key));
    return techDataFilterdState;
  }

  private createDimensionAndWeightData(techData: TechData[]): TechData[] {
    let keyFilter: string[] = [
      CAR_DETAILS_ENUM.LENGTH,
      CAR_DETAILS_ENUM.HEIGHT,
      CAR_DETAILS_ENUM.WIDTH,
      CAR_DETAILS_ENUM.WIDTH_WITH_MIRRORS,
      CAR_DETAILS_ENUM.TRUNK,
      CAR_DETAILS_ENUM.WHEELBASE,
      CAR_DETAILS_ENUM.FRONT_TRACK,
      CAR_DETAILS_ENUM.REAR_TRACK,
      CAR_DETAILS_ENUM.FRONT_OVERHANG,
      CAR_DETAILS_ENUM.REAL_OVERHANG,
      CAR_DETAILS_ENUM.TURNING_CIRCLE,
      CAR_DETAILS_ENUM.FUEL_TANK_CAPACITY
    ];
    const techDataFilterdState = techData?.filter((t) => keyFilter.includes(t.key));
    return techDataFilterdState;
  }

  private createEmissionsData(techData: TechData[]): TechData[] {
    let keyFilter: string[] = [
      CAR_DETAILS_ENUM.EMISSIONS_LOW,
      CAR_DETAILS_ENUM.EMISSIONS_MEDIUM,
      CAR_DETAILS_ENUM.EMISSIONS_HIGH,
      CAR_DETAILS_ENUM.EMISSIONS_EXTRA_HIGH,
      CAR_DETAILS_ENUM.EMISSIONS_COMBINED
    ];
    const techDataFilterdState = techData?.filter((t) => keyFilter.includes(t.key));
    return techDataFilterdState;
  }
  private createConsumptionsData(techData: TechData[]): TechData[] {
    let keyFilter: string[] = [
      CAR_DETAILS_ENUM.CONSUMPTION_LOW,
      CAR_DETAILS_ENUM.CONSUMPTION_MEDIUM,
      CAR_DETAILS_ENUM.CONSUMPTION_HIGH,
      CAR_DETAILS_ENUM.CONSUMPTION_EXTRA_HIGH,
      CAR_DETAILS_ENUM.CONSUMPTION_COMBINED
    ];
    const techDataFilterdState = techData?.filter((t) => keyFilter.includes(t.key));
    return techDataFilterdState;
  }

  showRestartConfigurationConfirmPopup() {
    this.showRestartConfirmationModal = true;
  }

  restartConfiguration(reason: string){
    this.showRestartConfirmationModal = false;
    if(reason == 'confirm') {
      if(this.screenCastActive && this.ephemeralDeviceID) {
        this.closeModalEvent.emit()
        this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID).then(() => {
          this.autobahnClient.fromConfiguratorToModelSelection(this.ephemeralDeviceID, this.labels, this.families, this.priceStatus)
        }).catch((reason:any) => {
          if(!environment.production){
            console.error('Stop Monkeyway Session Went Wrong', reason)
          } else{
            console.error('Stop Monkeyway Session Went Wrong')
          }
        })
      }
      this.store.dispatch(RESET_LAST_MODEL())
      this.store.dispatch(RESET_SELECTED_MODEL())
      this.router.navigate(['/mxe/models'])
    }
  }

  openTridenteModal(){
    this.openTridentModal.emit()
  }

  openSniTab(){
    window.open(this.searchNewInventoryEndpoint['changingThisBreaksApplicationSecurity'], '_blank')
  }

  private initAdobeTracker() {
    if (typeof window.adobeDataLayer !== 'undefined') {
      window.adobeDataLayer.push({
        "event": "CC_changeView",
        "data": {
          "pageInfo": {
            "vehicleName": this.mxeAnalyticsService.normalizeStringForAnalytics(this.commercialName),
            "vehicleBrand": "mxe",
            "vehicleModelName": this.mxeAnalyticsService.normalizeStringForAnalytics(this.modelName),
            "vehicleID": this.currentCarModel.modelCode,
            "pageName": `car-configurator:summary`,
            "SysEnv": "mobile"
          },
          "component": {
            "configurator": {
              "stepName": `summary`,
              "stepNumber": "6",
              "configurationID": this.configId,
              "options": this.optionsForAnalytics,
              "completePrice": parseFloat(this.carConfigurationService.getTotalPrice()).toFixed(0),
              "vehicleID": this.currentCarModel.modelCode,
            },
            "dealerSearch": {
              "dealerID": this.dealerId,
            }
          }
        },
      });
    }
  }
}

export interface IWheelNormServiceResponse {
  errorCode: string
  errorMessage: string
}





