import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, fromEvent } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { CarModel, Family } from 'src/app/core/models/get-models-service.model';
import { GetLoadConfigurationRequest, LoadConfigurationResult } from 'src/app/core/models/load-conf-service.model';
import { ErrorDialogService } from 'src/app/core/services/error-dialog.service';
import { MaseratiService } from 'src/app/core/services/maserati.service';
import { APP_SETTINGS_CONFIGURATION_SAVED, APP_SETTINGS_SET_APPLICATION_STEP } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { CONFIGURATION_LOADED, RESET_CONFIGURATION_LOADED } from 'src/app/core/store/actions/configuration-actions';
import { ModelActions } from 'src/app/core/store/actions/models/models-exported-actions';
import { UnrealDesiredSceneStateActions } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import { IDomElementData, PAIRING_STEP_ENUM, STEP_ENUM } from '../../core/models/common.model';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { GetPrintSendRequest } from 'src/app/core/models/print-send.model';
import { environment } from 'src/environments/environment';
import { Layer, Optional } from 'src/app/core/models/interface-service.model';
import { ConfigItem } from 'src/app/core/store/initials/temporary-configs-initial-state';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-configuration-loader',
  templateUrl: './configuration-loader.component.html',
  styleUrls: ['./configuration-loader.component.scss'],
  animations: [
      trigger("listAnimation", [
      transition("* => *", [
        query(
          ":enter",
          [
            style({ opacity: 0.5,transform: 'translateY(200%)', animation: 'ease' }),
            stagger(500, [animate("1s .1s cubic-bezier(0.25, 1, 0.5, 1)", style({ opacity: 1,transform: 'translateY(0)' }))])
          ],
          { optional: true }
        )
      ])
    ])
  ]
})
export class ConfigurationLoaderComponent implements OnInit{

  private _cardContainerRef : ElementRef;
  @ViewChild('cardContainer') set cardContainerRef(element : ElementRef){
    if(element){
      this._cardContainerRef = element;
      const elements = document.querySelectorAll('[id*=_card]');
      elements.forEach(el => {
       var domElement = this.uiCommonService.toElementData(el.id)
       if(domElement){
        this.elementsData.push(domElement);
       }
      
      });
      this.scrollSub$ = fromEvent(element.nativeElement, 'scroll').subscribe(() => {
        const scrollPosition = element.nativeElement.scrollTop;
        const selectedElement = this.getSelectedElement(scrollPosition);
        if(selectedElement && this.screenCastActive){
          this.autobahnClient.setSelectedElementRestoreconfig(this.ephemeralDeviceID,selectedElement)
        }       
      })
    }
  }
  @Input() isMainScreen:boolean = true;
  @Input() ephemeralDeviceID: string
  @Input() configurationID: string

  @Input() set loadConfigSetter(value: boolean){
    this.isLoadConfiguration = value;
    if(this.isLoadConfiguration){
      this.loadConfiguration('label-1','label-2','label-3',null);
    }
  }
 
  @Input() set restoreConfigSetter(value: boolean){
    this.isRestoreConfiguration = value;
    if(this.isRestoreConfiguration){
      this.showRestoreConfiguration('label-2','label-1','label-3',null);
    }
  }

  @Input() set temporaryItemsFromStore(value){
    this.temporaryConfigItemsFromStore = value;
  }

  @Input() set imagePath(value){
    this.imagePathRestore = value
  }

  @Input() set selectedElementRestoreConfig(value){
    if(value){
      this.setScrollPosition(value.id)
    }
    
  }



  clientNameForm: FormGroup;
  errorLabel: string = '';
  configLoadResult: LoadConfigurationResult;
  @Input() families: Family[];
  modelState$: Subscription;
  valCodeEmpty: boolean = true;
  homeMenuIsOpen = false;
  priceStatus: boolean;
  PairingStep = PAIRING_STEP_ENUM;
  Step = STEP_ENUM;
  screenCastActive: boolean;
  mirroringStoppedByUser: boolean = false;
  isSuggestConfiguration: boolean = false;
  isRestoreConfiguration: boolean = false;
  isLoadConfiguration: boolean = true;

  //FOR RESTORE 
  temporaryConfigItemsFromStore : ConfigItem[] = [];
  private temporaryConfigItemsFromStore$: Subscription;
  optionalBinding : Optional[] = [];
  
  private ctaLanguageId: string;
  private ctaLanguageId$: Subscription;
  sendPdfCtaEnabled = false;
  sendPdfBasePath: string;
  isLoading = false;
  imagePathRestore : string
  optionSelected: UIOptItem[]
  elementsData : IDomElementData[] = [];
  windowReference;
  @Input() carModel: CarModel | null;
  @Input() options: UIOptItem[];
  @Input() hiddenOptions: UIOptItem[];
  @Input() defaultOptions : UIOptItem[];
  @Input() imageLayers: Layer[];
  @Input() imageView : string;
  @Input() packages: Pack[];
  @Input() optionsForAnalytics: string;
  
 

  private countryCode$: Subscription;
  private languageId$: Subscription;
  private ephemeralDeviceID$: Subscription;
  private labels$: Subscription;
  private screenCastActive$: Subscription
  private languageId: string;
  private countryCode: number | undefined;
  private scrollSub$: Subscription;
  private carModel$: Subscription;
  private specialGraphicOptionals$ : Subscription;
  
 constructor(
    private maseratiService: MaseratiService,
    private store: Store<MxeReducers>,
    private router: Router,
    private formBuilder: FormBuilder,
    private autobahnClient: AutobahnClient,
    private errorDialogService: ErrorDialogService,
    private uiCommonService: UiCommonService,
    private chg: ChangeDetectorRef,
    private el: ElementRef,
  ) {
    this.store.dispatch(APP_SETTINGS_SET_APPLICATION_STEP({applicationStep: STEP_ENUM.CONFIGURATION_LOADER}))
  }

  ngOnInit() : void {

    this.clientNameForm = this.formBuilder.group({
      clientName: ['', [Validators.required]],
    });
    if(this.isMainScreen) {
      this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
        countryCode => this.countryCode = countryCode
      )
  
      this.languageId$ = this.store.select(s => s.appState.languageId).subscribe(
        lang => this.languageId = lang
      )

      this.specialGraphicOptionals$ = this.store.select(s => s.interfaceState.interface).subscribe(
        (getInterface) => {
          const carImageLayer = getInterface.carImages.filter(c => c.viewType == 'EXT')[0]
          if(carImageLayer){
            this.imageView = carImageLayer.view
            this.imageLayers = carImageLayer.layer!
          }
        }
      )

      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive)
  
      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )

      this.modelState$ = this.store.select(m => m.modelsState.families).subscribe(families => {
        this.families = families
        if(this.screenCastActive){
          this.autobahnClient?.setFamilies(this.ephemeralDeviceID, this.families)
        }
      });

      this.carModel$ = this.store.select(c => c.modelsState).subscribe(modelState => {
        this.carModel = modelState.lastCarModel
        this.options = modelState.lastOptions
        this.hiddenOptions = modelState.lastHiddenOptions
        this.defaultOptions = modelState.lastDefaultOptions
        this.optionsForAnalytics = modelState.lastOptionsForAnalytics
        this.packages = modelState.lastPackages
        if(this.screenCastActive) {
          let lastConfigurationsFromStore = {
            lastCarModel: this.carModel,
            lastOptions: this.options,
            lastHiddenOptions: this.hiddenOptions,
            lastDefaultOptions: this.defaultOptions,
            lastOptionsForAnalytics: this.optionsForAnalytics,
            lastPackages: this.packages,
            imageLayers: this.imageLayers,
            imageView: this.imageView
          }
          this.autobahnClient.setLastConfigurationsFromStore(this.ephemeralDeviceID,lastConfigurationsFromStore);
        }
        
      })

      this.temporaryConfigItemsFromStore$ = this.store.select(s => s.temporary_configs_state).subscribe(
        (temporaryConfigs) => {
          this.temporaryConfigItemsFromStore = temporaryConfigs.configItems
          if(this.screenCastActive) {
            this.autobahnClient.setTemporaryItemsFromStore(this.ephemeralDeviceID,this.temporaryConfigItemsFromStore);
          }
          this.temporaryConfigItemsFromStore?.forEach(el => {
            this.getCarImage(el.configId)
          });
        }
      )
      this.initAdobeTracker()      
    }
  }
 

  ngOnChanges(changes: SimpleChanges) {
    if(this.clientNameForm) {
      this.clientNameForm.controls['clientName'].setValue(this.configurationID)
      typeof(this.configurationID) != undefined && this.configurationID? this.valCodeEmpty = false : this.valCodeEmpty = true;
    }
  }

  ngOnDestroy(){
    if(this.isMainScreen) {
      this.ephemeralDeviceID$.unsubscribe()
      this.countryCode$.unsubscribe()
      this.languageId$.unsubscribe()
      this.screenCastActive$.unsubscribe()
      this.temporaryConfigItemsFromStore$.unsubscribe()
      this.carModel$.unsubscribe()
      this.specialGraphicOptionals$.unsubscribe()
    }
    if(this.scrollSub$) {
      this.scrollSub$.unsubscribe()
    }
  }

  private getSelectedElement(scrollPosition: number) {
    if(this.elementsData && this.elementsData.length> 0){
      const closestElement = this.elementsData?.reduce((targetObj, currentObj) => {
        if(this._cardContainerRef.nativeElement.scrollTop === (this._cardContainerRef.nativeElement.scrollHeight - this._cardContainerRef.nativeElement.clientHeight)) {
          return currentObj.offTop > targetObj.offTop ? currentObj : targetObj;
        }
        const currentDistance = Math.abs(currentObj.offTop - scrollPosition);
        const closestDistance = Math.abs(targetObj.offTop - scrollPosition);
        return currentDistance < closestDistance ? currentObj : targetObj;
      })
      return closestElement
    } else {
      return undefined;
    }
  }

  
  private setScrollPosition(scrollPosition: string) {
    if(this._cardContainerRef) {
      const el = this._cardContainerRef.nativeElement as HTMLElement;
      const scrollTo = this.elementsData.find(e => e.id == scrollPosition)
      if(scrollTo){
        el.scrollTo({ top: scrollTo.offTop, behavior: 'smooth' });
        this.chg.detectChanges();
      }
    }
  }

  showRestoreConfiguration(id : string,id2 : string, id3 : string,e) {
    if(e){
      e.stopPropagation && e.stopPropagation()
    }
    this.isRestoreConfiguration = true;
     this.isLoadConfiguration == true ? this.isLoadConfiguration = false : this.isLoadConfiguration = false
     this.isSuggestConfiguration == true ? this.isSuggestConfiguration = false : this.isSuggestConfiguration = false
     var boxFirstId = [`#${id}`]
     let myTag = this.el.nativeElement.querySelector(boxFirstId);
     if (!myTag.classList.contains('swiper-pagination-bullet-active')) {
         myTag.classList.add('swiper-pagination-bullet-active');
       }
     var otherId = [`#${id2}`]
     otherId.forEach(element => {
     let myTag = this.el.nativeElement.querySelector(element);
     if (myTag.classList.contains('swiper-pagination-bullet-active')) {
          myTag.classList.remove('swiper-pagination-bullet-active');
     }
     });
    

     if(this.screenCastActive && this.isMainScreen){
      this.autobahnClient.initializeLoadConfiguration(this.ephemeralDeviceID, this.isLoadConfiguration, this.isRestoreConfiguration)
     }
  }

  loadConfiguration(id : string, id2 : string, id3 : string,e) {
    if(e){
      e.stopPropagation && e.stopPropagation()
    }
     
     this.isLoadConfiguration = true;
     this.isRestoreConfiguration == true ? this.isRestoreConfiguration = false : this.isRestoreConfiguration = false
     this.isSuggestConfiguration == true ? this.isSuggestConfiguration = false : this.isSuggestConfiguration = false
     var boxFirstId = [`#${id}`]
     let myTag = this.el.nativeElement.querySelector(boxFirstId);
     if (!myTag.classList.contains('swiper-pagination-bullet-active')) {
         myTag.classList.add('swiper-pagination-bullet-active');
       }
       //Con SUGGESTCONFIGURAION SAREBBE: var otherId = [`#${id2}` , `#${id3}`]
     var otherId = [`#${id2}`]
     otherId.forEach(element => {
     let myTag = this.el.nativeElement.querySelector(element);
     if (myTag.classList.contains('swiper-pagination-bullet-active')) {
          myTag.classList.remove('swiper-pagination-bullet-active');
     }
     });
     if(this.screenCastActive && this.isMainScreen){
      this.autobahnClient.initializeLoadConfiguration(this.ephemeralDeviceID, this.isLoadConfiguration, this.isRestoreConfiguration)
     }
  }

  showSuggestedConfiguration(id : string, id2 : string, id3 : string,e){
    e.stopPropagation && e.stopPropagation()
     this.isSuggestConfiguration = true;
     this.isRestoreConfiguration == true ? this.isRestoreConfiguration = false : this.isRestoreConfiguration = false
     this.isLoadConfiguration == true ? this.isLoadConfiguration = false : this.isLoadConfiguration = false
     var boxFirstId = [`#${id}`]
     let myTag = this.el.nativeElement.querySelector(boxFirstId);
     if (!myTag.classList.contains('swiper-pagination-bullet-active')) {
         myTag.classList.add('swiper-pagination-bullet-active');
       }
      var otherId = [`#${id2}` , `#${id3}`]
     otherId.forEach(element => {
     let myTag = this.el.nativeElement.querySelector(element);
     if (myTag.classList.contains('swiper-pagination-bullet-active')) {
          myTag.classList.remove('swiper-pagination-bullet-active');
     }
     });

  }

  loadCarConfig(configId: string | undefined | null = null) {
    if(!configId){
      this.store.dispatch(ModelActions.NEW_MODEL_SELECTED({currentCarModel: this.carModel!}))
      this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_MODEL_SELECTED({modelCode: this.carModel!.modelCode}))
      this.store.dispatch(ModelActions.MODEL_OPTIONS_PARSED({
        packages: this.packages,
        options: this.options,
        hiddenOptions: this.hiddenOptions,
        optionsForAnalytics: this.optionsForAnalytics,
        defaultOptions: this.defaultOptions}))
      this.router.navigate([`/mxe/detail/${this.carModel?.modelName}`])
    }
    else{
      const payload: GetLoadConfigurationRequest = {
        configId: configId,
      };
      this.maseratiService.loadCarConfig(payload).subscribe({
        next: (value) => {
          if (value.errorCode > 0) {
            console.warn((this.errorLabel = value.errorMessage));
            this.store.dispatch(RESET_CONFIGURATION_LOADED())
          } else {
            this.store.dispatch(APP_SETTINGS_CONFIGURATION_SAVED({configId: configId}))
            this.store.dispatch(CONFIGURATION_LOADED({ config: value.result }));
            this.configLoadResult = value.result
            this.setCurrentSelectedModel()
            this.router.navigate([`mxe/detail/${this.configLoadResult.modelName}`]);
          }
        },
        complete: () => {
          console.debug('Call to configuration loader completed')
        },
        error: (e) => {
          if(!environment.production){
            console.error(e)
          }
        },
      });

    }
   
  }
  
  onScreenCastActiveEmitter(value) {
    this.screenCastActive = value
  }
  
  private setCurrentSelectedModel() {
    let carModel = this.families.map(f => f.models).reduce((allModels, model) => allModels.concat(model)).find(m => m.modelName === this.configLoadResult.modelName)
    if(carModel){
      this.modelState$.unsubscribe()
      this.store.dispatch(ModelActions.NEW_MODEL_SELECTED({currentCarModel: carModel}))
      this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_MODEL_SELECTED({modelCode: carModel.modelCode}))
      if(this.screenCastActive) {
        this.autobahnClient.setConfiguratorNotLoaded(this.ephemeralDeviceID, carModel)
      }
    }
  }

  getValCode(event){
    if(event.target.value != ''){
      this.valCodeEmpty = false;
    }
    else{
      this.valCodeEmpty = true;
    }
  }

  showHomeMenu(){
    this.homeMenuIsOpen = true;
  }

  homeMenuStatusController(modalStatus) {
    this.homeMenuIsOpen = modalStatus;
    window.adobeDataLayer.push({
      "event": 'updatePageName',
      "data": {
      "pageInfo":
          { 
            "pageName": "configuration-loader" 
          }
        }
      })
  }

  setConfigurationId(event:any) {
    if(this.screenCastActive)
      this.autobahnClient.initializeLoadConfiguration(this.ephemeralDeviceID, this.isLoadConfiguration, this.isRestoreConfiguration, event.target.value);
  }

  returnToHome(){
    if(this.screenCastActive) {
      this.autobahnClient.initializeLoadConfiguration(this.ephemeralDeviceID, true, false, '')
      this.autobahnClient.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME)
    }
  }

  getLabel(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  async printPdf(configId : string) {
    this.isLoading = true;
    this.windowReference = window.open();
    this.printSend(configId)
  }

  private async printSend(configId : string) {
    var filteredConfig = this.temporaryConfigItemsFromStore.find( el => el.configId == configId);
    let fileURLString;
    this.sendPdfBasePath = filteredConfig?.pdfLink as string;
      const payload: GetPrintSendRequest = {
        country: `${this.countryCode}`,
        lang: this.languageId,
        configId: configId,
        type: 'pdf',
        model: filteredConfig?.modelCode!,
      };
      
      this.maseratiService.printSend(this.sendPdfBasePath, payload).subscribe({
        next: (value: Blob) => {
          const blob = new Blob([value], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
          fileURLString = fileURL;
          
        },
        complete: () => {
          this.isLoading = false
          this.windowReference!.location = fileURLString;
          this.chg.detectChanges()
          console.info('[MASERATI SERVICE] printSend call completed');
        },
        error: (e) =>{
          this.isLoading = false;
          if(!environment.production){
            console.error('An error occurred while creating the pdf file...', e)
          } else {
            console.error('An error occurred while creating the pdf file...')
          }
        }
      });
  }

  getCarImage(configId : string | null =  null) : string{
    let filteredConfig : ConfigItem;
    let familyCommercialCode : string = "";
    
    if(configId){
      filteredConfig  = this.temporaryConfigItemsFromStore.find( el => el.configId == configId) as ConfigItem;
    }
    else{
      filteredConfig = {
        carName: this.carModel?.commercialName ? this.carModel?.commercialName : "",
        savedOn: new Date().getTime(),
        options: this.options,
        hiddenOptions: this.hiddenOptions,
        defaultOptions: this.defaultOptions,
        optionals: [],
        imageLayers: this.imageLayers,
        configId: "",
        modelCode: this.carModel?.modelCode ? this.carModel?.modelCode : "",
        pdfLink: "",
        imageView: this.imageView,
        modelYear: this.carModel?.yearModel ? this.carModel?.yearModel : "",
      }
    }
    for(let i = 0; i < this.families.length; i++){
      for(let j = 0; j < this.families[i].models.length; j++){
        if(this.families[i].models[j].commercialName == filteredConfig.carName){
          familyCommercialCode = this.families[i].models[j].familyCommercialCode.toLowerCase();
        }
      }
    }
    this.optionSelected = filteredConfig.options.filter(opt => opt.status.selected == true)
    const configItems = this.optionSelected.concat(filteredConfig?.hiddenOptions).concat(filteredConfig?.defaultOptions);
    const uniqueConfigItems = [...new Set(configItems)]
    let path = `${environment.photocopier_endpoint}/${filteredConfig?.modelCode}/533/c300/gfx${filteredConfig?.imageView}.png?config=${this.uiCommonService.buildConfigForPhotocopier(filteredConfig?.imageLayers!, uniqueConfigItems, filteredConfig.optionals, [])}`
    const familyWithCorrectShadow: string[] = ['gr', 'gh', 'lv', 'qp'] 
    if(!familyWithCorrectShadow.includes(familyCommercialCode)){
      path = path.replace('shadow', 'shadow_app')
    }
    this.imagePathRestore = path
    return path
  }

  ExpirationTime(savedOn : number) : number{
    const savedDate = new Date(savedOn)
    const expDateTimeStamp = savedDate.setDate(savedDate.getDate() + 14)
    const convertExpDate  = new Date(expDateTimeStamp)
    const today : any = new Date();
    let days = Math.floor((convertExpDate.getTime() - today.getTime()) / 1000 / 60 / 60 / 24);
    return days
  }

  

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "configuration-loader", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }
}
