import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Pack, UIOptItem } from '../models/car-grid.model';
import { CAR_MODEL_NAME, IDomElementData, LABEL_TYPE_ENUM } from '../models/common.model';
import { ICTA } from '../models/country-prop-service.model';
import { Layer, Optional } from '../models/interface-service.model';

@Injectable()
export class UiCommonService {
  private labels: any

  constructor() {
   }

  /**
   * Initializes labels dictionary
   * @param labels 
   */
  initializeLabels(labels) {
    this.labels = labels
  }

  /**
   * Returns the LABEL_TYPE_ENUM in order to make it available in the html code
   */
  get LABEL_TYPE_ENUM(): typeof LABEL_TYPE_ENUM {
    return LABEL_TYPE_ENUM;
  }

  /**
   * Formats the 360 images path
   * @param modelName the model name
   * @returns 
   */
  getFamilyImage360BasePath(modelName: string, countryCode: number): string{
    //const base = 'https://www.maserati.com/static/model-images-360/MY22';
    let localBase = '../assets/360'
    let defaultTrim = ''
    switch(modelName.toLowerCase()) {
      case CAR_MODEL_NAME.GRECALE:
        defaultTrim = 'gr_tr_530'
        break
      case CAR_MODEL_NAME.MC20SR:
        defaultTrim = 'sr_mc20'
        break;
      case CAR_MODEL_NAME.MC20:
          defaultTrim = 'mc20_cr'
          break;  
      case CAR_MODEL_NAME.GRANTURISMO:
        defaultTrim = 'gt_fo'
        break;
      case CAR_MODEL_NAME.GHIBLI:
        localBase = '../assets/model-selector-static'
        defaultTrim = 'gh_tr'
        break;
      case CAR_MODEL_NAME.LEVANTE:
        localBase = '../assets/model-selector-static'
        defaultTrim = 'lv_mo'
        break;
      case CAR_MODEL_NAME.QUATTROPORTE:
        localBase = '../assets/model-selector-static'
        defaultTrim = 'qp_mo'
        break;
      case CAR_MODEL_NAME.GRANCABRIO:
        defaultTrim = 'gc_tr'
        break;
      case CAR_MODEL_NAME.G2:
        countryCode !== 107? defaultTrim = 'g2s': defaultTrim = 'g2s-jp'
      break;
      default:
        defaultTrim = 'gr_gt'
        break
    }
    return `${localBase}/${defaultTrim}/landscape/`;
  }

  /**
   * Returns base path for 360 images
   * @param trim - trim code
   * @returns 
   */
  getTrimImage360BasePath(trim: string, countryCode: number): string{
    const localBase = '../assets/360'
    // <--- G2 --->
    if(trim.toLowerCase().startsWith('g2s')){
      if(countryCode !== 107) return `${localBase}/g2s/landscape/`
      else return `${localBase}/g2s-jp/landscape/`
    }
    // <--- GRECALE --->
    if(trim.toLowerCase().startsWith('gr_gt'))
      return `${localBase}/gr_gt/landscape/`
    if(trim.toLowerCase().startsWith('gr_mo'))
      return `${localBase}/gr_mo/landscape/`
    if(trim.toLowerCase().startsWith('gr_tr'))
      return `${localBase}/gr_tr_530/landscape/`
    if(trim.toLowerCase().startsWith('gr_fo'))
      return `${localBase}/gr_fo/landscape/`
    // <--- GRANTURISMO --->
    if(trim.toLowerCase().startsWith('gt_tr'))
      return `${localBase}/gt_tr/landscape/`
    if(trim.toLowerCase().startsWith('gt_mo'))
      return `${localBase}/gt_mo/landscape/`
    if(trim.toLowerCase().startsWith('gt_fo'))
      return `${localBase}/gt_fo/landscape/`
    // <--- MC20 --->
    if(trim.toLowerCase().startsWith('mc20_cr'))
      return `${localBase}/mc20_cr/landscape/`
    // <--- GRANCABRIO --->
    if(trim.toLowerCase().startsWith('gc_fo'))
      return `${localBase}/gc_fo/landscape/`
    if(trim.toLowerCase().startsWith('gc_tr'))
      return `${localBase}/gc_tr/landscape/`
    if(trim.toLowerCase().startsWith('gc_mo'))
      return `${localBase}/gc_mo/landscape/`  
    // <--- GHIBLI --->
    if(trim.toLowerCase().startsWith('gh_gt'))
      return `../assets/model-selector-static/gh_gt/landscape/`
    if(trim.toLowerCase().startsWith('gh_mo'))
      return `../assets/model-selector-static/gh_mo/landscape/`  
    if(trim.toLowerCase().startsWith('gh_tr'))
      return `../assets/model-selector-static/gh_tr/landscape/`
     // <--- LEVANTE --->  
    if(trim.toLowerCase().startsWith('lv_gt'))
      return `../assets/model-selector-static/lv_gt/landscape/`  
    if(trim.toLowerCase().startsWith('lv_mo'))
      return `../assets/model-selector-static/lv_mo/landscape/`  
    if(trim.toLowerCase().startsWith('lv_tr'))
      return `../assets/model-selector-static/lv_tr/landscape/`
     // <--- QUATTROPORTE --->  
    if(trim.toLowerCase().startsWith('qp_mo'))
      return `../assets/model-selector-static/qp_mo/landscape/`  
    if(trim.toLowerCase().startsWith('qp_tr'))
      return `../assets/model-selector-static/qp_tr/landscape/`
    if(trim.toLowerCase().startsWith('qp_gt'))
      return `../assets/model-selector-static/qp_gt/landscape/`
    return `${localBase}/${trim?.toLowerCase()}/landscape/`;
  }

  /**
   * Gets the label according to the current set language
   * @param id
   * @param type
   * @param more
   * @param modelCode
   * @param defaultValue
   * @returns
   */
	getLabel(id: string, type = '', more = '', modelCode: string = '', defaultValue: string = '') {
    if(!this.labels)
      return defaultValue

    if(modelCode == '')
      modelCode = Object.keys(this.labels)[0]
		
    var content = '';
		var labelKey = id;

		switch (type) {
			case LABEL_TYPE_ENUM.DESCRIPTION:
				labelKey = `INFO_${labelKey}_DESCR`;
				break;
			case LABEL_TYPE_ENUM.NAME:
				labelKey = `INFO_${labelKey}_NAME`;
				break;
			case LABEL_TYPE_ENUM.COLOR:
        labelKey = `INFO_${labelKey}_${more}_NAME`;
				break;
			case LABEL_TYPE_ENUM.VIDEO:
        labelKey = `INFO_${labelKey}_VIDEO`;
				break;
		}

		try {
			//TODO review labels functionality
      if(this.labels[modelCode] && this.labels[modelCode][labelKey] != '' && this.labels[modelCode][labelKey] != null){
        content = this.labels[modelCode][labelKey]
      }

			// workaround to add group name to rims, cal and trims (required by Colapinto)
			if (more && _.contains(['CAL', 'RIMS', 'TRIMS'], more)) {
				content = this.getLabel(modelCode, more) + ' - ' + content;
			}
		} catch (e) {
      console.error(`Error while getting translation for ${id}`)
    }
    
		return content || defaultValue;
	}

  /**
   * Formats prices using the accounting library
   * @param price the option price 
   * @param currency the current currency
   * @param priceMask the price mask formatter
   * @returns 
   */
  formatPrice(price: string, currency: string, priceMask: string) {
    var item = priceMask.split('|');
    return this.formatMoney(price, currency + ' ' , item[0], item[1], item[2])
  }

  formatMoney(value: string, currencySymbol: string, thousandSeparator: string, decimalSeparator: string,  precision: string) : string {
    let numericValue = parseFloat(value);
    let numericPrecision = parseInt(precision, 10);
    let [integerPart, decimalPart] = numericValue.toFixed(Math.max(numericPrecision, 0)).split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    if (numericPrecision > 0) {
      decimalPart = decimalPart.padEnd(numericPrecision, '0');
      return `${currencySymbol} ${integerPart}${decimalSeparator}${decimalPart}`;
    } else {
      return `${currencySymbol} ${integerPart}`;
    }
  }
  
  

  /**
   * Checks if an option matches the search filter
   * @param option
   * @param searchFilter
   * @returns 
   */
  applySearchFilter(option: UIOptItem, searchFilter: string, modelCode: string): boolean {
    if(searchFilter){
      const title = this.getLabel(option.id, LABEL_TYPE_ENUM.NAME,'', modelCode)
      const description = this.getLabel(option.id, LABEL_TYPE_ENUM.DESCRIPTION,'', modelCode)
      let colors;
      if(option.colorList.length>0){
          colors = option.colorList.map(c => this.getLabel(option.id, LABEL_TYPE_ENUM.COLOR, c.colIdent, modelCode))
      }
  
      return title?.toLocaleLowerCase().indexOf(searchFilter) >= 0 || description?.toLocaleLowerCase().indexOf(searchFilter) >= 0 || colors?.filter(c => c.toLocaleLowerCase().indexOf(searchFilter) >= 0).length>0;
    }
    return true
  }

  /**
   * Filters colors visibility for a given search filter
   * @param optionId 
   * @param colorId 
   * @param searchFilter 
   * @returns 
   */
  applySearchFilterOnColor(optionId: string, colorId: string, searchFilter: string, modelCode: string): boolean {
    if(searchFilter){
      const colorName = this.getLabel(optionId, LABEL_TYPE_ENUM.COLOR, colorId, modelCode)
      return colorName?.toLocaleLowerCase().indexOf(searchFilter) >= 0
    }
    return true
  }

  /**
   * Transforms options and packages to DomElements
   * @param sectionId
   * @param optionId 
   */
  toElementData(sectionId: string, optionId = '', index = -1, isHighlight = false): IDomElementData | undefined {
    let domElem;
    if(isHighlight) {
      domElem = optionId != '' ? document.getElementById(`H_${sectionId}_${optionId}`) : document.getElementById(`H_${sectionId}`)
    } else {
      domElem = optionId != '' ? document.getElementById(`${sectionId}_${optionId}`) : document.getElementById(`${sectionId}`)
    }
    if(domElem){
      if(isHighlight) {
        return {
          elementIndex: index,
          id: optionId != '' ? `H_${sectionId}_${optionId}` : `H_${sectionId}`,
          offTop: domElem.offsetTop,
          offHeight: domElem.offsetHeight
        }
      } else {
        return {
          elementIndex: index,
          id: optionId != '' ? `${sectionId}_${optionId}` : `${sectionId}`,
          offTop: domElem.offsetTop,
          offHeight: domElem.offsetHeight
        }
      }
    }
    return undefined
  }

  /**
   * Checks if price is a number greater than zero
   * @param price - a string representing the price
   */
  isPriceValid(price: string): boolean {
    return Number.parseFloat(price) > 0
  }

  /**
   * Appends languageIds translations to labels
   * @param labels 
   * @param languages 
   * @returns 
   */
  updateLanguageIdsTranslation(labels, languages) {
    return { ...languages, ...labels}
  }

  formatAction(actionName: string, actionData: any): any {
    let action: ICTA = {
      name: actionName,
      enabled: actionData['enabled'],
      icon: actionData['icon'],
      label: actionData['label'],
      link: actionData['link'],
      relevant: actionData['relevant'],
      sidebar_main: actionData['sidebar_main'],
      st_key: actionData['st_key'],
      trackers: actionData['trackers'],
      description_key: actionData['description_key'],
      sort_number: actionData['sort_number']
    }
    return action
  }

  public groupBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
    return array.reduce((result, currentItem) => {
      const groupKey = String(currentItem[key]);
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentItem);
      return result;
    }, {});
  }

  buildConfigForPhotocopier(imageLayers: Layer[], uniqueConfigItems: UIOptItem[], optionalsBinding: Optional[], packs : Pack[]) {
    if(packs && packs.length > 0){
      let packsfiltered = uniqueConfigItems.filter(x => (x.layerGroup == 'PACK' || x.group == 'PACK'));
      if (packsfiltered && packsfiltered.length > 0){
       const filteredIds = packsfiltered.map( p => p.id)
       let packsFilteredByFilteredIds = packs.filter( pf => filteredIds.includes(pf.packageId) && pf.implicits && pf.implicits.length > 0)
       if(packsFilteredByFilteredIds && packsFilteredByFilteredIds.length > 0){
          packsFilteredByFilteredIds.map( p => p.implicits).reduce((a,r) => a.concat(r)).forEach( i =>{
            if(!uniqueConfigItems.find( x => x.id == i.id)){
              uniqueConfigItems.push(i)
            }
          })
       }
       
      }
    }

    let carImageLayers: string[] = []
    //Use carImageLayers to sort layers
    imageLayers?.forEach(layer => {
      if (layer.staticLayer && layer.staticLayer == '1') {
        carImageLayers.push(layer.layerGroup)
      } else {
        uniqueConfigItems.filter(o => o.layerGroup === layer.layerGroup)
          .forEach(item => {
            const layerOption = this.createConfigItem(item, uniqueConfigItems, optionalsBinding)
            layerOption.forEach(lo => {
              if(!carImageLayers.includes(lo))
              carImageLayers.push(lo)
            })
          })
      }
    })

    return carImageLayers?.join(';')
  }

  private createConfigItem(o: UIOptItem, uniqueConfigItems: UIOptItem[], optionalsBinding): string[] {
    let configItem = (o.status.colorSelected != null && o.status.colorSelected != '') ? `${o.group}/${o.id}/${o.status.colorSelected}` : `${o.group}/${o.id}`
    let configItemResults: string[] = []
    let referrals = optionalsBinding.filter(r => r.id === o.id)
    if (referrals && referrals.length > 0) {
      referrals.forEach(referral => {
        let configItemWithReferral = configItem
        let breakLoop = false
        while(!breakLoop) {
          let subReferrals = optionalsBinding.filter(r => r.id === referral?.referredOptional)
          if(!subReferrals || subReferrals.length === 0) {
            const finalOpt = uniqueConfigItems.find(o => o.id === referral?.referredOptional)
            if(finalOpt){
              configItemWithReferral += (finalOpt.status.colorSelected != null && finalOpt.status.colorSelected != '') ? `/${finalOpt.id}/${finalOpt.status.colorSelected}` : `/${finalOpt.id}`
              configItemResults.push(configItemWithReferral)
            }
            breakLoop = true;
          } else {
            subReferrals.forEach((subReferral) => {
              let loopReferral = subReferral
              let breakSubreferralLoop = false
              const firstLevelReferral = referral
              while(!breakSubreferralLoop){
                const referredOption = uniqueConfigItems.find(o => o.id === referral?.referredOptional)
                if (referredOption) {
                  configItemWithReferral += (referredOption.status.colorSelected != null && referredOption.status.colorSelected != '') ? `/${referredOption.id}/${referredOption.status.colorSelected}` : `/${referredOption.id}`
                  if(loopReferral.referredOptional != referral.id && loopReferral.referredOptional != referral.referredOptional){
                    referral = loopReferral
                    loopReferral = optionalsBinding.find(r => r.id === referral?.referredOptional)!
                    if(!loopReferral){
                      const finalOpt = uniqueConfigItems.find(o => o.id === referral?.referredOptional)
                      if(finalOpt){
                        configItemWithReferral += (finalOpt.status.colorSelected != null && finalOpt.status.colorSelected != '') ? `/${finalOpt.id}/${finalOpt.status.colorSelected}` : `/${finalOpt.id}`
                      }
                      configItemResults.push(configItemWithReferral)
                      configItemWithReferral = configItem
                      referral = firstLevelReferral
                      breakSubreferralLoop = true
                      breakLoop = true  
                    }
                  } else {
                    configItemResults.push(configItemWithReferral)
                    configItemWithReferral = configItem
                    referral = firstLevelReferral
                    breakSubreferralLoop = true
                    breakLoop = true
                  }
                } else {
                  breakSubreferralLoop = true
                  breakLoop = true
                }
              }
            })
          }
       }
      })
    } else {
      configItemResults.push(configItem)
    }
    return configItemResults
  }
}
