import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ICountry } from 'src/app/core/models/country.model';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { AppSettingsActions } from 'src/app/core/store/actions/app-settings/app-settings-exported-actions';
import { RESET_MODELS_STATE } from 'src/app/core/store/actions/models/models-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import { AutobahnClient } from '../../core/clients/autobahn.client';
import { PAIRING_STEP_ENUM } from '../../core/models/common.model';
import { Family } from 'src/app/core/models/get-models-service.model';

@Component({
  selector: 'mxe-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent{
  @Input() countries: ICountry[];
  @Input() selectedCountry: ICountry;
  @Input() isModalCountryOpen: boolean = false;
  @Output() modalCountryStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  activeCountryIndex:number;
  modalAreYouSure: boolean = false;
  families: Family[] = [];
  private pastIndexSelectCountry: number;

  screenCastActive: boolean;
  ephemeralDeviceID:string;
  private screenCastActive$: Subscription
  private ephemeralDeviceID$: Subscription
  private labels$: Subscription;
  private families$: Subscription;
  
  constructor(
    private store: Store<MxeReducers>,
    private router: Router,
    private uiCommonService: UiCommonService,
    private mxeAnalyticsService: MxeAnalyticsService,
    private autobahn: AutobahnClient,
    private chg: ChangeDetectorRef
    ) {
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive)

        this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
          res => this.ephemeralDeviceID = res
        )
     }

  ngOnInit(){

    this.families$ = this.store.select(m => m.modelsState.families).subscribe(families => {
      this.families = families
      this.createDetailsInfo()
  }); 
    this.activeCountryIndex = this.countries.indexOf(this.selectedCountry)
  }
  
  ngOnDestroy(){
    this.screenCastActive$.unsubscribe()
    this.ephemeralDeviceID$.unsubscribe()
  }
  
  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }
  
  private createDetailsInfo() {
    this.families.forEach(family => {
      this.labels$ = this.store.select(s => s.countryPropState.labels)
        .subscribe(
          (labels) => {
            this.uiCommonService.initializeLabels(labels)
            this.chg.detectChanges()
          }
        )
    }
    )
  }

  openChooseLanguageModal(){
    this.isModalCountryOpen = true;
    this.activeCountryIndex = this.countries.indexOf(this.selectedCountry)
  }

  /**
   * Updates selected index on country selection
   * @param index 
   */
  onSelectCountry(index:number){
    if (index != this.activeCountryIndex){
      this.pastIndexSelectCountry = this.activeCountryIndex;
      this.activeCountryIndex = index
    }
  }

  /**
   * Confirms country switch
   */
  onConfirm(){
    this.pastIndexSelectCountry = this.activeCountryIndex;
    this.isModalCountryOpen = false;
    this.modalCountryStatus.emit(this.isModalCountryOpen);
    this.selectedCountry = this.countries[this.activeCountryIndex]
    //Reset state after country's switch
    this.store.dispatch(AppSettingsActions.APP_SETTINGS_SWITCH_COUNTRY({
      countryCode: this.selectedCountry.countryCode,
      languageId: this.selectedCountry.availableLanguages.length > 0 ? this.selectedCountry.availableLanguages[0] : 'en',
      ctaLanguageId: this.selectedCountry.ctaLanguageId,
      showPrices: false,
      arePricesAvailable: false
    }))
    this.store.dispatch(RESET_MODELS_STATE())
    //reset countryProp
    this.router.navigate(['/mxe'])
    if(this.screenCastActive) {
      this.autobahn.switchCountry(this.ephemeralDeviceID, this.selectedCountry)
      this.autobahn.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME)
    }
  }

  areYouSureModal(){
    this.modalAreYouSure = true;
  }

  /**
   * Cancels pending country switch
   */
  onCancel(){
    this.isModalCountryOpen = false;
    this.modalCountryStatus.emit(this.isModalCountryOpen);
    if(this.pastIndexSelectCountry != null){
      this.activeCountryIndex = this.pastIndexSelectCountry;
    }
  }

  /**
   * Calls normalize for analytics
   */
  normalizeForAnalytics(value: string): string{
    return this.mxeAnalyticsService.normalizeStringForAnalytics(value)
  }
}

