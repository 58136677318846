<div class="mxe__model--selector-container overlay">
   <button type="button" class="btn burger-menu" data-linktracking ="side-nav:preferences:open" (click)="showHomeMenu()">
      <span class="bar1"></span>
      <span class="bar2"></span>
      <span class="bar3"></span>
   </button>
   <img class="mxe__fixed-top-logo" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg"
      alt="Maserati">
   <button 
      *ngIf="isMainScreen" 
      class="mxe__close-action" 
      data-linktracking = "cta:choose-trim:close" 
      (click)="closeTrimModal()">
      Back to models
   </button>
   <div class="swiper-button-prev" data-linktracking ="engagement:choose-trim:arrow-left">
      {{subFamilyNames[currentIndex-1] }}
   </div>
   <div class="swiper-button-next" data-linktracking ="engagement:choose-trim:arrow-right">
      {{subFamilyNames[currentIndex+1] }}
   </div>
   <mxe-pairing-toggle
      [pairingStep]="PairingStepEnum.TRIM_SELECTOR"
      [labels] = "labels"
      [isMainScreen]="isMainScreen"
      [currentTrimCode]="currentTrimCode"
      [priceStatus]="showPrices"
      [selectedFamily]="selectedFamily"
      [activeSlideIndex]="swiper?.swiperRef?.activeIndex"
      (screenCastActiveEmitter)="onScreenCastActiveEmitter($event)">
   </mxe-pairing-toggle>
   <div class="rectangle-blurred rectangle-left"></div>
   <div class="rectangle-blurred rectangle-right"></div>
   <div class="mxe__model--selector-carousel">
      <swiper 
         [config]="config" 
         [pagination]="pagination"
         (swiper)="onSwiper($event)" 
         (slideChange)="onSlideChange()">
         <ng-template *ngFor="let f of selectedFamily?.models; let i=index" swiperSlide>
            <div class="mxe__model--selector-slide text-center">
               <div class="container-360-wrapper trim-selector">
                  <div class="cloudimage-360" 
                     id="{{selectedFamily.models[i].modelName.toLowerCase() + '-trim'}}"
                     (touchmove) = getActualIndex($event)
                     [attr.data-folder]="getTrimImage360BasePath(selectedFamily.models[i].modelName)"
                     [attr.data-filename-x]="'{index}.png'" 
                     data-index-zero-base=2
                     data-amount-x="36" 
                     data-hide-360-logo=true 
                     [attr.data-bottom-circle]="showDataBottomCircle(selectedFamily.familyCommercialCode)"
                     data-bottom-circle-offset=25
                     data-hotspots=false 
                     [attr.data-image-list-x]="getDataImageList(selectedFamily.familyCommercialCode)">
                     </div>
               </div>
               <div class="mxe__bottom-car-selector-wrapper">
                  <div class="mxe__bottom-car-selector-infos">
                     <div class="mxe__selector-family-name-price">
                        <h2>{{subFamilyNames[currentIndex]}}</h2>
                        <span *ngIf="showPrices">
                           <h4>{{getStartingPrice(currentTrimCode)}}</h4>
                        </span>
                     </div>
                     <div class="selector-bottom-row ">
                        <div class="selector-bottom-column-triple">
                           <div class="info-bottom-row">
                              <div class="mxe__model-dimensions-info-grid" *ngFor="let dato of parsedTechData">
                                 <div class="mxe__model-dimensions-info-item">
                                    <span>{{dato.label}}</span> <b>{{dato.value}}</b>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="selector-bottom-column">
                           <button class="mxe__button-select-family"
                               [ngClass]="loadButton ? 'spinning' : '' "
                               [disabled]="loadButton"
                               (click)="updateSelectedCarModel(f)" 
                               data-linktracking="cta:choose-trim:select"
                               [routerLink]="['/mxe/detail', f.modelName]">
                              <img *ngIf="loadButton" class="spinning" src="../../../assets/icons/mxe-ico-refresh-black.svg">
                              <span *ngIf="!loadButton">{{getLabel("MXE_SELECT_TRIM_BUTTON")}}</span>
                           </button>
                          
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </ng-template>
      </swiper>
   </div>
</div>
<mxe-fullscreen-loader *ngIf="selectedFamily && selectedFamily.models && selectedFamily.models.length > 0" [code]="selectedFamily.models[0].modelCode"></mxe-fullscreen-loader>
<app-home-menu (homeMenuStatusChange)="homeMenuStatusController($event)" *ngIf="homeMenuIsOpen"></app-home-menu>