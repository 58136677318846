<div class="mxe__car-configurator-wrapper" [ngClass]="{
    'fullview': fullScreen, 
    'sidebar_mode': !fullScreen, 
    'sniEnable': showPopUp && isMainScreen && isSearchNewInventoryCtaEnabled && showSni,
    'sniDisable': !showPopUp && isMainScreen && isSearchNewInventoryCtaEnabled && showSni}">

    <button type="button" class="btn burger-menu" data-linktracking ="side-nav:preferences:open" (click)="homeMenuIsOpen = true">
        <span class="bar1"></span>
        <span class="bar2"></span>
        <span class="bar3"></span>
    </button>

    <mxe-pairing-toggle *ngIf="!showCinematic"
        [isMainScreen]="isMainScreen"
        [priceStatus]="priceStatus"
        [pairingStep]="PairingStep.CAR_CONFIGURATOR"
        [packages]="modelPacks"
        [carModel]="carModel"
        [menuItems]="menuItems"
        [modelOptions]="modelOptions"
        [labels]="labels"
        [closedAccordions]="closedAccordions"
        [scrollPosition]="autobahnScrollPosition"
        [dealerId]="dealerId"
        [viewUi] = "viewUi"
        [accordionExp] = "accordionExp"
        (screenCastActiveEmitter)="onScreenCastActiveEmitter($event)"
        (mirroringStoppedByUserEmitter)="mirroringStoppedByUser = $event">
    </mxe-pairing-toggle>

       

    <!-- car model name -->
    <app-car-model-data class="lang-selected-{{languageId}}"
        [ephemeralDeviceID]="ephemeralDeviceID"
        [isMainScreen]="true"
        [screenCastActive]="screenCastActive"
        [idleTimeIsExpired]="idleTimeIsExpired"
        [carModelData]="carModel"
        [currentTrimFamily]="currentTrimFamily"
        [price]="getModelPrice()"
        [priceStatus]="priceStatus"
        [showCinematic]="showCinematic"
        (valueChange)="tridentModalStatusController($event)">
    </app-car-model-data>

    <!-- video streaming -->
    <div class="maserati__car-configurator-video-wrapper" *ngIf="trimIsImplemented" 
    [ngClass]="{'cast-is-active': screenCastActive && !showCinematic}">
        <app-monkey-way
            [screenCastActive]="screenCastActive"
            [carModel]="carModel"
            [bAudio]="bAudio"
            [viewPresenterScreenSidebar]="viewUi"
            (viewPresenterScreenSidebarEvent)="togglePresenterScreenSidebar($event)"
            (stopScreenMirroringEvent)="showScreenCastAlert = !showScreenCastAlert"
            (leaveSessionEvent)="onStopMirroring()"
            (monkeyWayReady)="onMonkeyWayReady($event)"
            (isTrimAvailableEventEmitter)="isTrimAvailableStreaming = $event"
        ></app-monkey-way>
    </div>

    <div *ngIf="!streamingAvailable" class="maserati__car-configurator-video-wrapper fallback-2d">
        <app-fallback2D 
            [isTrimAvailableStreaming] = "isTrimAvailableStreaming"
            [activeCamera]="activeView"
            [defaultOptions]="defaultOptions"
            [optionalsBinding]="optionalsBinding"
            [hiddenOptions]="hiddenOptions"
            [options]="modelOptions"
            [carModel]="carModel"
            [swiperViews]="swiperViews"
            (onFullScreenToggle)="onFullScreenToggle = $event"
            [loadingCompleted] = "loadingCompleted"
            [isMainScreen]="true"
            (onActiveImageChange)="activeView = $event"
            [viewPresenterScreenSidebar]="viewUi"
            [packages]="modelPacks"
            (viewPresenterScreenSidebarEvent)="togglePresenterScreenSidebar($event)"
        ></app-fallback2D>
    </div>

    <!-- right configuration sidebar -->
    <div *ngIf="showContentData" class="maserati__car-configurator-sidebar">

        <mxe-changed-configuration-modal *ngIf="showChangeConfigurationWindow && carModel != null"
            [modelName]="carModel.modelName"
            [modelCode]="carModel.modelCode"
            [showPrices]="priceStatus" 
            [conflictsData]="conflictsData" 
            [currency]="carModel.currency"
            [priceMask]="carModel.priceMask" 
            (confirmEmitter)="onConfirmEmitter($event)">
        </mxe-changed-configuration-modal>

        <app-sidebar-configurator [ngStyle]="{'pointer-events': showPopUp && isSearchNewInventoryCtaEnabled ? 'none' : 'auto'}"
            [streamingAvailable]="streamingAvailable"
            [cameras]="cameras" 
            [carModel]="carModel" 
            [configId]="configId"
            [dealerId]="dealerId" 
            [isMainScreen]="true" 
            [menuItems]="menuItems"
            [options]="modelOptions" 
            [optionsForAnalytics]="optionsForAnalytics" 
            [packages]="modelPacks"
            [priceStatus]="priceStatus" 
            [saveCtaEnabled]="saveCtaEnabled"
            [searchFilter]="searchValue" 
            [searchToggleValue]="searchToggleValue"
            [updateAnalyticsPageName]="updateAnalyticsPageNameEvt"
            [standAloneSet]="standAloneSet"
            [standAloneSetInt]="standAloneSetInt"
            [swiperViews]="swiperViews"
            [languageId]="languageId"
            [isSearchNewInventoryCtaEnabled] = "isSearchNewInventoryCtaEnabled"
            [screenCastActive]="screenCastActive"
            [isCinematicActiveForSelectedTrim]="isCinematicActiveForSelectedTrim"
            (accordionToggleEvent)="accordionToggle = $event"
            (changeCameraEvt)="changeCamera($event)"
            (changeConfigurationEvent)="onShowChangeConfigurationAlertBar($event)"
            (propagateSearchBoxEvent)="onPropagateSearchBoxEvent($event)"
            (propagateSearchBoxToggleEvent)="onPropagateSearchBoxToggleEvent($event)"
            (scrollPositionEvent)="scrollPosition = $event" 
            (showSaveModal)="save()"
            (showPopUpSni)="showPopUpSni()"
            (showSummaryModal)="openSummary()"
            (accordionExpEvent)="onaccordionExpEvent($event)"
            (packageDetailsEmitter)="onPackageDetailsEmitter($event)"
            (closedAccordionsEmit)="onClosedAccordionsEmit($event)"
            (openedAccordionsEmit)="onOpenedAccordionsEmit($event)"
            (standAloneSetEditingEmitter)="onStandSetAloneEditingEmitter($event)"
            (standAloneSetEditingIntEmitter)="onStandSetAloneEditingIntEmitter($event)"
            (changeImageEvt)="activeView = $event"
            (packageSwiperSlideChanged)="onPackageSwiperSlideChanged($event)"
            (showCinematicEvent)="showCinematicEvent($event,false)">
        </app-sidebar-configurator>
    </div>

    <!-- bottom interactive bar -->
    <app-interactive-bottom-bar *ngIf="streamingAvailable && isInteractiveBottomBarVisible && !showCinematic"
        [countryCode] = "countryCode"
        [monkeyWayStreamingReady] = "monkeyWayStreamingReady"
        [activeCamera]="activeCamera" 
        [activeEnvironment]="activeEnvironment" 
        [cameras]="cameras"
        [dayNight]="dayNight" 
        [environments]="environments"
        [screenCastIconActive]="screenCastActive"
        [activeFamilyCommercialCode]="activeFamilyCommercialCode"
        [xRayEnabled]="xrayEnabled"        
        (fullScreenToggleEvent)="onFullScreenToggle = $event"
        (screenMirroringEvent)="!screenCastActive ? screenCastModalToggle() : showScreenCastAlert = !showScreenCastAlert"
        (hotspotsToggleEvent)="onHotspotsToggleEvent($event)"
        (testPipelineEvent)="testPipeline()">
    </app-interactive-bottom-bar>

    <mxe-changed-configuration-bar *ngIf="showChangeConfigurationAlertBar" class="changes-bottom-bar"
        [ngClass]="{'show-bar':showChangeConfigurationAlertBar}"
        (changedConfigurationEmitter)="showChangeConfigurationAlertWindow()">
    </mxe-changed-configuration-bar>

    <div *ngIf="showSaveModal || showCustomSaveModal" class="mxe__popup-iframe frame-more-info">
        <div class="search-inventory-heading">
            <span class="inventory-search-title"> {{getLabel('SO_TAB_SAVE')}} </span>
            <span class="trim-selector-close" (click)="closeSaveModal()"></span>
        </div>
        <iframe *ngIf="safeUrlWithParams" #iframe width="1020px" height="760px" id="myIframe" [src]="safeUrlWithParams"></iframe>
    </div>

   <app-summary-configuration *ngIf="showSummaryModal" 
        [currentCarModel]="carModel" 
        [countryCode]="country"
        [languageId]="languageId"
        [priceStatus]="priceStatus" 
        [optionsForAnalytics]="optionsForAnalytics"
        [configId]="configId"
        [options]="modelOptions"
        [packages]="modelPacks"
        [defaultOptions]="defaultOptions"
        [dealerId]="dealerId"
        [menuItems]="menuItems"
        [ctas] = "ctas"
        [config] = "desiredUnrealConfig"
        [hiddenOptions]="hiddenOptions"
        [isMainScreen]="true"
        [optionalsBinding]="optionalsBinding"
        [imageView]="imageView"
        [imageLayers]="imageLayers"
        [customerName]="customerName"
        [customerSurname]="customerSurname"
        [showCustomerName]="showCustomerName"
        [unformattedBasePrice]="unformattedBasePrice"
        [unformattedEquipments]="unformattedEquipments"
        [unformattedTaxes]="unformattedTaxes"
        [totalPrice]="getModelPrice()"
        [isCinematicActiveForSelectedTrim]="isCinematicActiveForSelectedTrim"
        [showCinematicFromWrapper]="showCinematic"
        [streamingAvailable]="streamingAvailable"
        [isOnCloud]="isOnCloud"
        [isOnPremise]="isOnPremise"
        [idleTimeIsExpired]="idleTimeIsExpired"
        (onScrollPosition)="summaryScrollPosition = $event"
        (onToggleAccordion)="toggleAccordion($event)"
        (closeModalEvent)="closeSummary()"
        (openSaveModalEvent)="onOpenSaveModalEvent()"
        (openTridentModal)=" openTridentModal()"
        (showCinematicEventFromSummary)="showCinematicFromSummaryEvent($event[0], $event[1])">
    </app-summary-configuration>

    <app-thank-you-page *ngIf="showThankYouPage"
        (closeThanksPage)="toggleThankYouPage()"
        [dealerCode]="dealerId"
        [configId]="configId"
        [currentCarModel]="carModel" 
        [countryCode]="country"
        [languageId]="languageId"
        [sendPdfBasePath]="sendPdfBasePath"
        [ctas] = "ctas"
        [isMainScreen]="true">    
    </app-thank-you-page>

    <mxe-fullscreen-loader *ngIf="carModel != null" 
        [isWrapper]="isWrapper"
        [isMainScreen]="isMainScreen"
        [screenCastActive]="screenCastActive"
        [ephemeralDeviceID]="ephemeralDeviceID"
        [code]="carModel.modelCode"
        (loadingCompleted)= "onLoadingCompleted()"
        (refreshPageEvent)="refreshPage()">
    </mxe-fullscreen-loader>

    <app-stop-screen-cast-modal *ngIf="showScreenCastAlert" 
        (modalClosingEvent)="showScreenCastAlert = false"
        (modalConfirmedEvent)="onStopMirroring()">
    </app-stop-screen-cast-modal>

    <app-trident-modal *ngIf="isTridentModalOpen" 
        [carModel]="carModel"
        [countryCode]="countryCode"
        [languageId]="languageId"
        [currentFamily]="currentFamily"
        [showSummaryModal]="showSummaryModal"
        [hiddenOptions]="hiddenOptions"
        [modelOptions]="modelOptions"
        [defaultOptions]="defaultOptions"
        [packages]="modelPacks"
        [optionsForAnalytics]="optionsForAnalytics"
        (valueChange)="tridentModalStatusController($event)"
        (closeSummary)="closeSummary()"
        >
    </app-trident-modal>
    <mxe-afk-modal 
    *ngIf="(idleTimeIsExpired && !streamingAvailable && envName === 'cloud') ||
           (idleTimeIsExpired && streamingAvailable && envName === 'cloud' && !isCinematicActiveForSelectedTrim) ||
           (idleTimeIsExpired && streamingAvailable && (envName === 'cloud' || envName === 'on_premise') && isCinematicActiveForSelectedTrim)" 
        [streamingAvailable] = "streamingAvailable"
        [envName]="envName"
        [isCinematicActiveForSelectedTrim]="isCinematicActiveForSelectedTrim"
        [isCinematicStarted]="isCinematicStarted"
        (continueBrowsingEvent)="continueBrowsing()"
        (confirmExpirationEvent)="confirmExpiration()"
        (isCinematicStartedEvent)="onIsCinematicStarted($event)">
    </mxe-afk-modal>   
</div>

<app-home-menu *ngIf="homeMenuIsOpen" 
    [streamingMode]="envName"
    [ephemeralDeviceId]="ephemeralDeviceID"
    (homeMenuStatusChange)="closeHomeMenu()">
</app-home-menu>

<div class="outer-sniiframe" *ngIf="isSearchNewInventoryCtaEnabled && showSni && showPopUp" (click)="showPopUp = false"></div>
<div class="mxe__popup-iframe frame-search-inventory" 
    [ngClass]="{'active' : showPopUp,
                'inactive': !showPopUp,
                'on-premise': isOnPremise}"
             *ngIf="isSearchNewInventoryCtaEnabled && showSni">
    <div class="search-inventory-heading">
        <div [ngClass]="{'on-premise-titles': isOnPremise}">
            <span class="inventory-search-title" [ngClass]="{'on-premise':isOnPremise}"> Matches your configuration</span>
            <span *ngIf="isOnPremise">{{carsCounter}} {{carModel.commercialName}} similar to your configuration</span>
        </div>
        <div class="ctas-container">
            <button class="btn btn-bordered" (click)="showPopUp = false">
                <span class="btn-label">{{getLabelDefValue('BACK','Indietro')}}</span>
            </button>
            <button class="btn btn-bordered btn-arrow-black" (click)="openSniTab(); showPopUp = false" 
                [ngClass]="{'on-premise':isOnPremise}"
                data-linktracking="cta:summary:discover-cars" *ngIf="isOnPremise">
                <span class="btn-label">{{getLabelDefValue('DISCOVER_AVAILABLE_VAR','discover available cars')}}</span>
            </button>
            <button  [disabled]="onSummaryNavigation"
                class="btn btn-accent" 
                [ngClass]="{'btn-arrow-black': !onSummaryNavigation,
                            'btn-spin': onSummaryNavigation,
                            'on-premise':isOnPremise
                           }" 
                (click)="openCinematicOrSummary()">
                <span class="btn-label">{{getLabelDefValue('NO_THANKS_GO_TO_SUMMARY','No grazie, vai al riepilogo')}}</span>
            </button>
        </div>
    </div>
    <iframe class="iframe" *ngIf="isOnCloud && isCongifIdCreated" #iframe id="mySearchInventoryFrame" [src]="searchNewInventoryEndpoint"></iframe>
</div>

<app-save-form *ngIf="showCustomSaveModal"
[isSavingAvailable]="isSaveButtonAvailable"
[languageId]="languageId"
[countryCode]="countryCode"
(customerNameChange)="customerNameValue = $event"
(customerSurNameChange)="customerSurNameValue = $event"
(emailChange)="emailValue = $event"
(mobileInput)="mobileInputValue = $event"
(modelOfInterestChange)="modelOfInterest = $event" 
(SaveChange)="saveFromCustomFormSave()"></app-save-form>

<!-- START CINEMATIC -->
<button *ngIf="(showCinematicEventFromSummary || showCinematic) && !idleTimeIsExpired && audioButtonVisible"  
    class="cinematic-audio"
    [ngClass]="{'active': isAudioOn}" 
    (click)="audioOnOff()">
    <div  class="tools-container">
        <img class="audio-img" *ngIf="!bAudio" src="../../../assets/icons/volume_off.svg">
        <img class="audio-img" *ngIf="bAudio" src="../../../assets/icons/volume_on.svg">
    </div>
    <div class="audio-mode" [ngClass]="{'active': isAudioOn}" *ngIf="bAudio">Audio:&nbsp;<b>ON</b></div>
    <div class="audio-mode" [ngClass]="{'active': isAudioOn}" *ngIf="!bAudio">Audio:&nbsp;<b>OFF</b></div>
</button>

<button *ngIf="(showCinematicEventFromSummary || showCinematic) && !idleTimeIsExpired && canSkipCinematic" 
    class="cinematic-skip"
    (click)="skipCinematic()">
    <img src="../../../assets/icons/mxe__ico-skip-button.svg">
</button>

<div class="cinematic-text-container" *ngIf="(showCinematicEventFromSummary || showCinematic) && !idleTimeIsExpired && showCinematicLogo">
    <div class="cinematic-text">
        <img class="logo" [ngClass]="{'not-active': !showCinematicText}" src="../../../assets/maserati-cinematic-logo.svg">
        <div class="dedication-text" [ngClass]="{'not-active': !showCinematicText}" *ngIf="!showCustomerName">presents</div>
        <div class="dedication-text" [ngClass]="{'not-active': !showCinematicText}" *ngIf="showCustomerName">X</div>
        <div class="ml12" [ngClass]="{'active': textAnimationActive}">{{customerName}} {{customerSurname}}</div>
    </div>
</div>
<div class="cinematic-background-text-container" *ngIf="(showCinematicEventFromSummary || showCinematic) && !idleTimeIsExpired && showCinematicLogo"></div>
<!-- END CINEMATIC -->


<!-- START RENDER PIPELINE -->
<div id="pipeline" style="position: absolute; z-index: 14;height: fit-content;
    width: fit-content; top:8vh; left:25vw; display: flex; align-items: center; justify-content: center; flex-direction: column; gap:5px;" *ngIf="renderPipelineImgUrl">
    <ng-container *ngFor="let imgUrl of renderPipelineImgUrlArray; let i=index">
        <div style="position: relative;">
            <img [src]="imgUrl" style="height: 40vh; width: auto; box-shadow: 10px 10px 10px rgba(0,0,0, 0.5);" >
            <img style="position: relative; margin-left: -40px; top: -10px; width: 30px; height:auto;" src="../../../assets/icons/mxe__ico-download-black.svg" (click)="downloadImg(i)">
        </div>
    </ng-container>
</div>
<div style="position:absolute; width: 100vw; height: 100vh; background-color: black; top:0; z-index:11; opacity:0.5;" 
     *ngIf="renderPipelineImgUrl" (click)="closeImgPipeline()">
</div>

<div style="position:absolute; width: 100vw; height: 100vh; text-align: center; background-color: black; display: flex; justify-content: center;
            align-items: center; top:0; z-index:15; opacity:0.4;" 
     *ngIf="isPipelineLoading">
    <div class="spinner"></div>
</div>
<!-- END RENDER PIPELINE -->
  

