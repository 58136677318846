<div id="main_sidebar" class="maserati__car-configurator-sidebar">

    <div class="car-upper-menu-container">
        <app-new-car-upper-menu 
            [activatedItem]="activeItem" 
            [menu]="menuItems" 
            [searchBoxValue]="searchFilter"
            [options]="options"
            [languageId]="languageId"
            (searchBoxEmitter)="onSearchBoxEvent($event)" 
            (searchBoxToggleEvent)="onSearchBoxToggleEvent($event)"
            (selectMenuItem)="goToSection($event)"
            [searchToggleValue]="searchToggleValue">
        </app-new-car-upper-menu>
    </div>
    <div class="maserati__car-configurator-sidebar--interior" id="sidebar-element" #sidebar
        [ngStyle]="{'bottom' : (!isMainScreen) ? '0' : ''}">
        <div id="sec-h" *ngIf="isSectionVisible(MENU_ENUM.HIGH)" class="sidebar-el">
            <app-highlight-configuration
            [menu]="menuItems" 
            [modelCode]="modelCode"
            [modelName]="modelName"
            [familyCommercialCode]="carModel.familyCommercialCode"
            [options]="options"
            [showPrices]="priceStatus"
            [currency]="currency"
            [priceMask]="priceMask"
            [packages]="packages"
            [isMainScreen]="isMainScreen"
            [currentState] = "currentStateHigh"
            [currentStateHighPacksDetail]="currentStateHighPacksDetail"
            [packagesSwiperSlideChange]="packagesSwiperSlideChange"
            [searchFilter]="searchFilter"
            [standAloneSetHighlightPacks]="standAloneSetHighlightPacks"
            [accordionOpen]="accordionOpen"
            (changeConfigurationEmitter)="onShowChangeConfigurationAlertBar($event)"
            (swiperSlideChanged)="onPackageSwiperSlideChanged($event)"
            >
            </app-highlight-configuration>
        </div>
        <div id="sec-1" *ngIf="isSectionVisible(MENU_ENUM.EXT)" class="sidebar-el">
            <app-exterior-configuration
                [commercialName]="commercialName" 
                [configId]="configId"
                [currency]="currency" 
                [dealerId]="dealerId" 
                [isMainScreen]="isMainScreen"
                [menu]="menuItems" 
                [modelCode]="modelCode" 
                [modelName]="modelName"
                [options]="options"
                [optionsForAnalytics]="optionsForAnalytics"
                [priceMask]="priceMask"
                [searchFilter]="searchFilter"
                [showPrices]="priceStatus"
                [familyCommercialCode]="carModel.familyCommercialCode"
                (changeCameraEvt)="changeCameraEvt.emit($event)"
                (changeConfigurationEmitter)="onShowChangeConfigurationAlertBar($event)"
                [currentState] = "currentStateExt"
                [showMoreLivree]="showMoreLivree">
            </app-exterior-configuration>
        </div>
        <div id="sec-2" *ngIf="isSectionVisible(MENU_ENUM.INT)" class="sidebar-el">
            <app-interior-configuration 
                [commercialName]="commercialName" 
                [configId]="configId"
                [currency]="currency" 
                [dealerId]="dealerId" 
                [menu]="menuItems" 
                [modelCode]="modelCode"
                [modelName]="modelName"
                [options]="options"
                [optionsForAnalytics]="optionsForAnalytics"
                [priceMask]="priceMask" 
                [searchFilter]="searchFilter"
                [showPrices]="priceStatus"
                [standAloneSetInt]="standAloneSetInt"
                [isMainScreen]="isMainScreen"
                (changeCameraEvt)="changeCameraEvt.emit($event)"
                (changeConfigurationEmitter)="onShowChangeConfigurationAlertBar($event)"
                (standAloneSetEditingIntEmitter)="onStandAloneSetEditingIntEmitter($event)"
                [currentState] = "currentStateInt">
            </app-interior-configuration>
        </div>
        <div id="sec-3" *ngIf="isSectionVisible(MENU_ENUM.PACK)" class="sidebar-el">
            <app-packages-configuration 
                [commercialName]="commercialName" 
                [configId]="configId"
                [currency]="currency" 
                [dealerId]="dealerId" 
                [menu]="menuItems"
                [modelCode]="modelCode"
                [modelName]="modelName"
                [optionsForAnalytics]="optionsForAnalytics"
                [options]="options"
                [packages]="packages"
                [priceMask]="priceMask"
                [searchFilter]="searchFilter"
                [showPrices]="priceStatus"
                [standAloneSet]="standAloneSet"
                [isMainScreen]="isMainScreen"
                [packageDetails]="packageDetails"
                [accordionExp] = "accordionExp"
                [packagesSwiperSlideChange]="packagesSwiperSlideChange"
                [formatSelection]="packagesFormatSelectionDetail"
                [accordionOpenCloseSetter]="accordionOpenClose"
                (accordionExpEvent)="onaccordionExpEvent($event)"
                (showPackageDetailEmitter)="onShowPackageDetailEmitter($event)"
                (changeConfigurationEmitter)="onShowChangeConfigurationAlertBar($event)"
                (standAloneSetEditingEmitter)="onStandAloneSetEditingEmitter($event)"
                (swiperSlideChanged)="onPackageSwiperSlideChanged($event)"
                >
            </app-packages-configuration>
        </div>
        <div id="sec-4" *ngIf="isSectionVisible(MENU_ENUM.OPT)" class="sidebar-el">
            <app-optional-configuration 
                [commercialName]="commercialName" 
                [configId]="configId"
                [currency]="currency" 
                [dealerId]="dealerId" 
                [menu]="menuItems" 
                [modelCode]="modelCode"
                [modelName]="modelName"
                [options]="options" 
                [formatSelection]="optFormatSelectionDetail"
                [optionsForAnalytics]="optionsForAnalytics"
                [priceMask]="priceMask" 
                [searchFilter]="searchFilter"
                [showPrices]="priceStatus"
                [isMainScreen]="isMainScreen"
                (changeConfigurationEmitter)="onShowChangeConfigurationAlertBar($event)"
                [currentState] = "currentStateOpt">
            </app-optional-configuration>
        </div>
        <div id="sec-5" *ngIf="isSectionVisible(MENU_ENUM.ACC)" class="sidebar-el">
            <app-accessories-configuration
                [commercialName]="commercialName" 
                [configId]="configId"
                [currency]="currency" 
                [dealerId]="dealerId"
                [formatSelection]="accFormatSelectionDetail" 
                [isMainScreen]="isMainScreen"
                [menu]="menuItems" 
                [modelCode]="modelCode"
                [modelName]="modelName"
                [options]="options" 
                [optionsForAnalytics]="optionsForAnalytics"
                [showPrices]="priceStatus"
                [priceMask]="priceMask" 
                [searchFilter]="searchFilter"
                (changeConfigurationEmitter)="onShowChangeConfigurationAlertBar($event)"
                [currentState] = "currentStateAccessories">
            </app-accessories-configuration>
        </div>
    </div>
    <div class="car-bottom-menu-container" *ngIf="isMainScreen">
        <div class="mxe__navigation-buttons">
            <button *ngIf="saveCtaEnabled" class="btn btn-bordered btn-transparent btn-save-configuration"
                data-linktracking="cta:configurator:save" 
                (click)="showSaveModal.emit()">
                {{getLabel('SO_SAVE_SUBMIT')}} </button>
            <button class="btn btn-accent btn-go-summary" *ngIf="isSearchNewInventoryCtaEnabled" 
                data-linktracking="cta:configurator:summary"
                (click)="showPopUpSni.emit()">
                {{getLabel('CONFIGURATION_RESUME')}} <img src="../../../assets/icons/ico_summary.svg" alt="summary">
            </button>
            <!-- Aprire cinematic se non c'è SNI -->
            <button class="btn btn-accent btn-go-summary" *ngIf="!isSearchNewInventoryCtaEnabled" 
                data-linktracking="cta:configurator:summary"
                (click)="openCinematicOrSummary()">
                {{getLabel('CONFIGURATION_RESUME')}} <img src="../../../assets/icons/ico_summary.svg" alt="summary">
            </button>
        </div>
    </div>
</div>