import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorDialogService } from 'src/app/core/services/error-dialog.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { DialogConfig } from './dialog-config';
import { AutobahnClient } from '../../core/clients/autobahn.client';
import { Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { MxeReducers } from 'src/app/core/store/reducers';
import { PAIRING_STEP_ENUM, STEP_ENUM } from '../../core/models/common.model';
import { environment } from 'src/environments/environment';
import { MonkeyWayService } from 'src/app/core/services/monkey-way.service';
import { RESET_SELECTED_MODEL, SET_LAST_MODEL } from 'src/app/core/store/actions/models/models-actions';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { RESET_CONFIGURATION_LOADED } from 'src/app/core/store/actions/configuration-actions';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['../car-configurator-wrapper/afk-modal/afk-modal.component.scss']
})
export class ErrorDialogComponent {

  @ViewChild("alertContainer", { read: ViewContainerRef }) container;

  private ephemeralDeviceID: string
  private screenCastActive: boolean
  private protocol: string
  private host: string
  private hostName: string
  private applicationStep: string
  private carModel;
  private options;
  private optionsForAnalytics;
  private packs;
  private hiddenOptions;
  private defaultOptions;
  ephemeralDeviceID$: Subscription
  screenCastActive$: Subscription
  modelStateOptSub$: Subscription
  modelStateOptForAnalytics$: Subscription
  configIdAnalytics$: Subscription
  modelStatePackSub$: Subscription
  hiddenOptionsSub$: Subscription
  defaultOptions$: Subscription
  applicationStep$: Subscription


  constructor(
    public config: DialogConfig,
    private errorDialogService: ErrorDialogService,
    private router: Router,
    private uiCommonService: UiCommonService,
    private autobahn: AutobahnClient,
    private store: Store<MxeReducers>,
    private monkeyWayService: MonkeyWayService
  ) { }


  ngOnInit() {
    if (this.errorDialogService.mainScreen) {
      combineLatest([this.store.select(s => s.appState.protocol), this.store.select(s => s.appState.host), this.store.select(s => s.appState.hostName)]).subscribe(
        ([protocol, host, hostName]) => {
          this.protocol = protocol
          this.host = host
          this.hostName = hostName})

      this.modelStateOptSub$ = this.store.select(s => s.modelsState).subscribe(modelState => {
        this.carModel = modelState.currentCarModel!
        this.options = modelState.options
      })
      this.modelStateOptForAnalytics$ = this.store.select(m => m.modelsState.optionsForAnalytics).subscribe(
        (optionsForAnalytics: string) => this.optionsForAnalytics = optionsForAnalytics
      )
      this.modelStatePackSub$ = this.store.select(s => s.modelsState.packages).subscribe(
        (packs: Pack[]) => {
          this.packs = packs;

        }
      )
      this.hiddenOptionsSub$ = this.store.select(s => s.modelsState.hiddenOptions).subscribe(
        (options: UIOptItem[]) => {
          this.hiddenOptions = options;
        }
      )
      this.defaultOptions$ = this.store.select(s => s.modelsState.defaultOptions).subscribe(
        opts => {
          this.defaultOptions = opts
        }
      )
      this.applicationStep$ = this.store.select(s => s.appState.applicationStep).subscribe({
        next:(step: string) => {
          this.applicationStep = step
        }
      })
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe({
        next: (res: boolean) => {
          this.screenCastActive = res
        }
      });
      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )

    }
  }
  ngAfterViewInit() {
    if (!this.errorDialogService.mainScreen) {
      setTimeout(() => {
        this.onClose()
        if (this.config?.data?.action == 'redirect') {
          location.reload()
        }
      }, 5000)
    }
  }
  ngOnDestroy() {
    if (this.errorDialogService.mainScreen) {
      this.screenCastActive$.unsubscribe()
      this.ephemeralDeviceID$.unsubscribe()
      this.modelStateOptForAnalytics$?.unsubscribe()
      this.modelStateOptSub$?.unsubscribe()
      this.hiddenOptionsSub$?.unsubscribe()
      this.defaultOptions$?.unsubscribe()
      this.modelStatePackSub$?.unsubscribe()
      this.applicationStep$?.unsubscribe()
    }
  }

  public onClose() {
    this.errorDialogService.removeDialogComponentFromBody()
  }

  public onHomepage() {
    this.errorDialogService.removeDialogComponentFromBody()
    if (this.screenCastActive) {
      //Stop everything on TV (pairing and video streaming)
      this.autobahn.onLeaveScreenCast(this.ephemeralDeviceID)
    }
    if(this.applicationStep !== STEP_ENUM.CAR_CONFIGURATOR) {
      window.location.href = environment.homepage_url;
    } else {
      this.monkeyWayService.stopMonkeyWaySession()
      this.resetLoadedConfiguration()
      window.location.href = environment.homepage_url;
    }
  }

  resetLoadedConfiguration() {
    this.store.dispatch(SET_LAST_MODEL(
      {lastCarModel: this.carModel, 
       lastHiddenOptions: this.hiddenOptions, 
       lastOptions: this.options,
       lastDefaultOptions: this.defaultOptions,
       lastPackages: this.packs,
       lastOptionsForAnalytics: this.optionsForAnalytics
      }))
    this.store.dispatch(RESET_SELECTED_MODEL())
    this.store.dispatch(RESET_CONFIGURATION_LOADED())
  }

  getLabelWithDefaultValue(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

}
