import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MxeReducers } from "../store/reducers";
import { AutobahnClient } from '../clients/autobahn.client';
import { Subscription, interval } from 'rxjs';
import { environment } from "src/environments/environment";
import { ErrorDialogService } from './error-dialog.service';

@Injectable({
  providedIn: 'root'
})

export class HeartbeatService {
  heartbeatInterval: Subscription | null = null;
  heartBeatTimer: number
  ephemeralDeviceID: string
  screenCastActive: boolean
  mirroringStoppedByUser: boolean

  ephemeralDeviceID$: Subscription
  screenCastActive$: Subscription


  constructor(private store: Store<MxeReducers>, private autobahn: AutobahnClient, private errorDialogService: ErrorDialogService) {
    this.heartBeatTimer = environment.autobahn_configuration.heartbeat.heartBeatTimer

    this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
      ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
    )
    this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
      screenCastActive => {
        this.screenCastActive = screenCastActive
        if (this.screenCastActive) {
          this.startHeartbeat()
        } else {
          this.stopHeartbeat()
        }
      }
    )
  }

  private startHeartbeat() {
    if (!this.heartbeatInterval) {
      this.setHeartbeatWithRetry(this.ephemeralDeviceID, this.heartBeatTimer);
    }
  }


  private setHeartbeatWithRetry(ephemeralDeviceID: string, heartbeatTimer: number) {
    let retryCount = 0;
    const maxRetries = environment.autobahn_configuration.heartbeat.heartbeat_max_retries;
    const retryAfter = environment.autobahn_configuration.heartbeat.heartbeat_retry_after;
    let lastErrorTime: number = 0;
  
    const setHeartbeat = async () => {
      try {
        await this.autobahn.setHeartbeat(ephemeralDeviceID, heartbeatTimer);
        // Successful call
        retryCount = 0;
      } catch (error: any) {
        this.clearHeartbeat();
        retryCount++;
        const currentTime = Date.now();
        console.warn(`Error setting heartbeat ${retryCount} at ${new Date().toLocaleString()}: ${error.error}`);
        
        if (retryCount < maxRetries) {
          // Calculate the delay based on the time elapsed since the last error
          const delay = Math.max(0, retryAfter - (currentTime - lastErrorTime));
          await new Promise(resolve => setTimeout(resolve, delay));
          lastErrorTime = currentTime;
        } else {
          console.error(`${new Date().toLocaleString()} Max retry count exceeded. Unable to set heartbeat.`);
          retryCount = 0;
          this.autobahn.sessionJoined.emit(false);
          return;
        }
      }
      
      if (!this.heartbeatInterval) {
        this.heartbeatInterval = interval(heartbeatTimer).subscribe(() => {
          setHeartbeat();
        });
      }
    };
  
    setHeartbeat();
  }
  

  private clearHeartbeat() {
    if (this.heartbeatInterval) {
      this.heartbeatInterval.unsubscribe();
      this.heartbeatInterval = null;
    }
  }



  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      this.heartbeatInterval.unsubscribe();
      this.autobahn.clearTimeout(this.ephemeralDeviceID)
      this.heartbeatInterval = null;
      if (!this.mirroringStoppedByUser) {
        this.errorDialogService.open({ data: { message: 'Something went wrong. Mirroring connection lost. Insert a new code to start again.' }, })
      }
      this.mirroringStoppedByUser = false;
    }
  }

  ngOnDestroy() {
    this.ephemeralDeviceID$.unsubscribe()
    this.screenCastActive$.unsubscribe()
    this.heartbeatInterval?.unsubscribe()
  }

}