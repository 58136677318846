import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { CarModel, Family } from 'src/app/core/models/get-models-service.model';
import { Subscription } from 'rxjs';
import { MxeReducers } from 'src/app/core/store/reducers';
import { Store } from '@ngrx/store';
import { AutobahnClient } from '../../../core/clients/autobahn.client';
import { PAIRING_STEP_ENUM } from '../../../core/models/common.model';
import { MonkeyWayService } from '../../../core/services/monkey-way.service';
import { ModelActions } from 'src/app/core/store/actions/models/models-exported-actions';
import { APP_SETTINGS_SET_STREAMING_STATUS } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { RESET_CONFIGURATION_LOADED } from 'src/app/core/store/actions/configuration-actions';
import { RESET_SELECTED_MODEL, SET_LAST_MODEL } from 'src/app/core/store/actions/models/models-actions';
import { Pack, UIOptItem } from 'src/app/core/models/car-grid.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trident-modal',
  templateUrl: './trident-modal.component.html',
  styleUrls: ['./trident-modal.component.scss']
})
export class TridentModalComponent implements OnInit {

  @Input() carModel: CarModel
  @Input() languageId: string
  @Input() countryCode: string
  @Input() currentFamily: Family
  @Input() showSummaryModal
  @Input() hiddenOptions: UIOptItem[] = [];
  @Input() defaultOptions: UIOptItem[] = [];
  @Input() packages: Pack[] = [];
  @Input() optionsForAnalytics: string = '';
  @Input() modelOptions: UIOptItem[] = [];
  @Output() valueChange = new EventEmitter();
  @Output() closeSummary = new EventEmitter();

  ephemeralDeviceID$: Subscription;
  screenCastActive$: Subscription;
  families$: Subscription;
  labels$: Subscription;
  priceStatus$: Subscription;


  ephemeralDeviceID: string
  screenCastActive: boolean
  families: Family[] = []
  labels: any
  priceStatus: boolean
  isSummaryOpened : boolean
  modelCode: string;
  
  constructor(
    private router: Router,
    private uiCommonService: UiCommonService,
    private store: Store<MxeReducers>,
    private autobahnClient: AutobahnClient
  ) { }
  

  ngOnInit(): void {
    this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
      ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
    )

    this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
      screenCastActive => this.screenCastActive = screenCastActive
    )

    this.families$ = this.store.select(s => s.modelsState.families).subscribe(
      families => this.families = families
    )

    this.labels$ = this.store.select(s => s.countryPropState.labels).subscribe(
      labels => this.labels = labels
    )

    this.priceStatus$ = this.store.select(s => s.appState.showPrices).subscribe(
      priceStatus => this.priceStatus = priceStatus
    )

    this.modelCode = this.carModel.familyCommercialCode;
    this.initAdobeTracker()
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes['showSummaryModal'] && changes['showSummaryModal'].currentValue == true){
      this.isSummaryOpened = changes['showSummaryModal'].currentValue
    }

  }

  ngOnDestroy() {
    this.ephemeralDeviceID$.unsubscribe()
    this.screenCastActive$.unsubscribe()
    this.families$.unsubscribe()
    this.labels$.unsubscribe()
    this.priceStatus$.unsubscribe()
  }

  closeTrimModal(){
    this.valueChange.emit(false);
  }
  getLabel(optId: string) {
    return this.uiCommonService.getLabel(optId)
  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "side-nav:configurator", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }

  private resetStreamingAvailable() {
    this.store.dispatch(APP_SETTINGS_SET_STREAMING_STATUS({streamingAvailable: true}))
    if(this.screenCastActive) {
      this.autobahnClient.setStreamingStatus(this.ephemeralDeviceID, true)
    }
    
  }

  goToHomePage() {
    try {
      this.resetStreamingAvailable();
      this.resetLoadedConfiguration();
      if (this.screenCastActive && this.ephemeralDeviceID) {
        this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID)
          .then(() => {
            this.autobahnClient.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.HOME);
            this.autobahnClient.setModelSelectorActiveIndex(this.ephemeralDeviceID, 0);
            this.autobahnClient?.setCustomerName(this.ephemeralDeviceID, '');
            this.autobahnClient?.setCustomerSurname(this.ephemeralDeviceID, '');
          })
          .catch((reason: any) => {
            throw new Error(`Stop Monkeyway Session Error: ${reason}`);
          });
      }
  
      if (this.isSummaryOpened) {
        this.closeSummary.emit();
      }
    } catch (error: any) {
      throw new Error(`goToHomePage Error: ${error.message}`);
    }
  }
  
  goToModels() {
    this.resetStreamingAvailable()
    this.resetLoadedConfiguration()
    if(this.screenCastActive && this.ephemeralDeviceID) {
      this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID).then(() => {
        this.autobahnClient.fromConfiguratorToModelSelection(this.ephemeralDeviceID, this.labels, this.families, this.priceStatus)
      }).catch((reason:any) => {
        if(!environment.production){
          console.error('Stop Monkeyway Session Went Wrong', reason)
        } else{
          console.error('Stop Monkeyway Session Went Wrong')
        }
      })
    }
    if(this.isSummaryOpened){
      this.closeSummary.emit()
    }
  }

  goToTrims() {
    this.resetStreamingAvailable()
    this.resetLoadedConfiguration()
    if(this.screenCastActive && this.ephemeralDeviceID) {
      this.autobahnClient.stopMonkeyWaySession(this.ephemeralDeviceID).then(() => {
        this.autobahnClient.fromConfiguratorToTrimSelection(this.ephemeralDeviceID, this.labels, this.currentFamily, this.priceStatus)
      })
    }
    if(this.isSummaryOpened){
      this.closeSummary.emit()
    }
    
  }

  resetLoadedConfiguration() {
    this.store.dispatch(SET_LAST_MODEL(
      {lastCarModel: this.carModel, 
       lastHiddenOptions: this.hiddenOptions, 
       lastOptions: this.modelOptions,
       lastDefaultOptions: this.defaultOptions,
       lastPackages: this.packages,
       lastOptionsForAnalytics: this.optionsForAnalytics
      }))
    this.store.dispatch(RESET_SELECTED_MODEL())
    this.store.dispatch(RESET_CONFIGURATION_LOADED())
  }
}