import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { UIOptItem } from 'src/app/core/models/car-grid.model';
import { IConflictsData, IDomElementData, IMAGE_TYPE_ENUM, MENU_ENUM } from 'src/app/core/models/common.model';
import { Menu, Tab } from 'src/app/core/models/interface-service.model';
import { CarConfigurationService } from 'src/app/core/services/car-configuration.service';
import { MxeAnalyticsService } from 'src/app/core/services/mxe-analytics.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { MxeReducers } from 'src/app/core/store/reducers';


@Component({
  selector: 'app-accessories-configuration',
  templateUrl: './accessories-configuration.component.html',
  styleUrls: ['./accessories-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ 
    trigger('openCloseAcc', [
      state('open', style({ 
          'height': '*'
        })),
      state('closed', style({ 
          'height': '0px'
        })),
      transition('open <=> closed', [
      animate('1.5s cubic-bezier(0.25, 1, 0.5, 1)')])
      ])
 ],
})
export class AccessoriesConfigurationComponent {
  @Input() options: UIOptItem[];
  @Input() menu: Menu[]
  @Input() modelCode: string;
  @Input() currency: string;
  @Input() priceMask: string;
  @Input() showPrices: boolean; 
  @Input() set searchFilter(inputText: string) {
    let text = inputText
    if(text && text != '') this.inputText = inputText.toLowerCase()
    else this.inputText = text
  } 


  @Input() configId: string;
  @Input() optionsForAnalytics: string;
  @Input() dealerId: string;
  @Input() modelName: string;
  @Input() commercialName: string;
  @Input() formatSelection : string;
  @Input() isMainScreen: boolean;

  @Output() changeConfigurationEmitter = new EventEmitter<IConflictsData>()

  inputText: string;
  selectedOption: string = '';
  menuItems: Menu | undefined;
  selectedColor: any = {};
  visibleTabs: string[] = [];
  menuLabel: string = '';
  visibleSections: string[] = []; 
  accordionOpened = true;
  totalOptSelected : number = 0;
  screenCastActive$ : Subscription;
  screenCastActive : boolean;
  ephemeralDeviceID$ : Subscription;
  ephemeralDeviceID : string;
  private domOptionElements: IDomElementData[] = [];
  heightArray : any[] = [];
  setHeightDiv : number;
  visibleItems : Tab[] | undefined;

 
  //NUMBER OF ACCORDION IN ACCESSORIES
  @Input() currentState : string[]
  

  constructor(
    public uiCommonService: UiCommonService,
    private chg: ChangeDetectorRef,
    private carConfigurationService: CarConfigurationService,
    private el: ElementRef,
    private mxeAnalyticsService: MxeAnalyticsService,
    private store: Store<MxeReducers>,
    private autobahn : AutobahnClient
  ) { }

  ngOnInit(): void {  
    this.menuItems = this.menu.find(x => x.id === MENU_ENUM.ACC)
    if(this.menuItems?.id){
      this.menuLabel = this.menuItems?.id;
    }
    else{
      this.menuLabel = 'Accessories';
    }
    this.visibleItems = this.menuItems?.tabs.filter(t => this.isTabVisible(t.id));
    this.currentState = ["open","open","open","open","open","open","open","open"];
    if(this.isMainScreen){
      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive
       )
      this.ephemeralDeviceID$  = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
         ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes['options']){
      let totalSelectedAccessories : UIOptItem[] = []
      var menuAcc = this.menu.filter(m => m.id == MENU_ENUM.ACC)
      menuAcc.forEach(m =>{
        m.tabs.forEach(t =>{
          var accessoriesOptions = changes['options'].currentValue.filter( c => c.group == t.id)
          if(accessoriesOptions && accessoriesOptions.length > 0){
            var selectedAccessories = accessoriesOptions.filter(x => x.status.selected == true)
            if(selectedAccessories && selectedAccessories.length > 0){
             let selectedAccessoriesQuantity = selectedAccessories.filter(x => x.quantity)
             selectedAccessoriesQuantity.forEach( a => {
              totalSelectedAccessories.push(a)
             })
            }
          }
        })
      })
      let sum = 0;
      if(totalSelectedAccessories.length > 0){
        totalSelectedAccessories.forEach(a =>{
             sum += a.quantity
        })
      }
      this.totalOptSelected = sum
      this.formatSelectionDetails();
    }
    
  }


  ngOnDestroy(){
    if(this.screenCastActive$){
      this.screenCastActive$.unsubscribe();
    }
    if(this.ephemeralDeviceID$){
      this.ephemeralDeviceID$.unsubscribe();
    }
  }



  
  /**
   * Update the configuration adding one instance of an accessory
   * @param acc_id 
   * @param quantity 
   * @param sectionId 
   * @param price 
   */
  addAccessories(acc_id: string, quantity: number) {
    this.carConfigurationService.updateQuantity(acc_id, (quantity ?? 0) + 1)
    this.chg.detectChanges();
    this.formatSelectionDetails();
  }

  /**
   * Update the configuration removing one instance of an accessory
   * @param acc_id 
   * @param quantity 
   * @param sectionId 
   * @param price 
   */
  removeAccessories(acc_id: string, quantity: number) {
    this.carConfigurationService.updateQuantity(acc_id, quantity - 1)
    this.chg.detectChanges();
    this.formatSelectionDetails();
  }



  /**
   * Gets the image path
   * @param group 
   * @param optionId 
   * @returns 
   */
  getImagePath(group: string, optionId: string): string {
    return this.carConfigurationService.getImagePath(this.modelCode, group, optionId, IMAGE_TYPE_ENUM.INFO_1400_875)
  }

  /**
   * Get the option's properties translation
   * @param id - option id
   * @param type - type (name, description, color)
   * @param color - color id
   * @returns 
   */
  getLabel(id: string, type = '') {
    return this.uiCommonService.getLabel(id, type, '', this.modelCode);
  }

  /**
   * Toggles accordion visibility
   * @param dataaccordion 
   */
 
  toggleAccordion(dataaccordion: string,i,k, e): void {
    e.stopPropagation && e.stopPropagation()
    this.accordionOpened = !this.accordionOpened

    if(i != null){
      this.currentState[i] = this.currentState[i]  === 'open' ? 'closed' : 'open';
    }
    if(k != null){
      this.currentState[k] = this.currentState[k]  === 'open' ? 'closed' : 'open';
    }

    
    if(this.isMainScreen && this.screenCastActive){
      this.autobahn.setAccordionState(this.ephemeralDeviceID,this.currentState,'acc')
    }

    const boxes = [`#${dataaccordion}_header`, `#${dataaccordion}_content` ]

    boxes.forEach(box => {
      let myTag = this.el.nativeElement.querySelector(box) as HTMLElement;
      if(myTag) {
         if(!myTag.classList.contains('closed')) {
          myTag.classList.add('closed');
        } else {
          myTag.classList.remove('closed');
        }
      }
    })
 
  }
  
  /**
   * Returns the number of selected option per section and the total price of selection (if prices are shown)
   * @param sectionId 
   * @returns 
   */
   getSelectedOptionDetails(tabId: string): string{
    let result: string = '';
    let quantity = 0
    let totalPrice = 0
    this.options.filter(o => o.status.selected === true).forEach(option => {
      if (this.visibleSections.includes(option.section) && option.group === tabId) {
        quantity += option.quantity
        totalPrice += (+option.price * option.quantity)
      }
    })
    let formattedPrice = ''
    if(totalPrice > 0){
      formattedPrice = ' - ' + this.formatPrice(totalPrice.toString())
    } 
    if(quantity > 1) {
      var selected = this.getLabel("MXE_SELECTED_PLUR_M");
      result = `<span>${quantity} ${selected} </span>${this.showPrices ? `<span class='selected-price-details'>${formattedPrice}</span>` : ''}`
    } else if(quantity > 0) {
      var selected = this.getLabel("MXE_SELECTED_SING_M");
      result = `<span>${quantity} ${selected}</span>${this.showPrices ? `<span class='selected-price-details'>${formattedPrice}</span>` : ''}`
    }
    
    return result
  }
  
  formatSelectionDetails(): string {
    var formatSelection : string = "";
    if(this.totalOptSelected < 2){
      var selected = this.getLabel("MXE_SELECTED_SING_M");
      formatSelection = `${this.totalOptSelected} ${selected}`;
    }
    if(this.totalOptSelected > 1){
      var selected = this.getLabel("MXE_SELECTED_PLUR_M");
      formatSelection = `${this.totalOptSelected} ${selected}`;
    }
    if(this.screenCastActive || !this.isMainScreen){
      this.autobahn.setAccFormatSelectionDetail(this.ephemeralDeviceID,formatSelection);
    }
    return formatSelection;
  }

  /**
   * Format price using currency and priceMask
   * @param price 
   * @returns 
   */
  formatPrice(price: string): string {
    if(this.uiCommonService.isPriceValid(price))
      return this.uiCommonService.formatPrice(price, this.currency, this.priceMask)
    return ''
  }

  /**
   * Get all options for a given section
   * @param sectionId 
   */
   getOptionsBySection(sectionId: string, filter_fuoriserie: string): UIOptItem[] {
    if(filter_fuoriserie != null) {
    if(filter_fuoriserie === '1'){
      const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))  
      const options_with_fuoriserie_colors = this.options.filter(o => 
        o.section === sectionId 
        && o.onlyImplicit === false
        && o.fuoriserie === false 
        && o.colorList.length > 0 
        && o.colorList.filter(c => c.fuoriserie === true).length > 0
        && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
      
      return fuoriserie_options.concat(options_with_fuoriserie_colors)
    } 
    return this.options.filter(o => 
      o.section === sectionId 
      && o.fuoriserie === false 
      && o.onlyImplicit === false 
      && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
    }
    return  this.options.filter(o => o.section === sectionId && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
  }

  /**
   * Returns if the section is visible or not
   * @param sectionId 
   * @returns 
   */
  sectionIsVisible(sectionId: string, filter_fuoriserie): boolean {
    if (filter_fuoriserie != null) {
      if (filter_fuoriserie === '1') {
        const fuoriserie_options = this.options.filter(o => o.section === sectionId && o.fuoriserie === true && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        const options_with_fuoriserie_colors = this.options.filter(o =>
          o.section === sectionId
          && o.onlyImplicit === false
          && o.fuoriserie === false
          && o.colorList.length > 0
          && o.colorList.filter(c => c.fuoriserie === true).length > 0
          && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode))
        if (fuoriserie_options.concat(options_with_fuoriserie_colors).length > 0) {
          if (this.visibleSections.includes(sectionId) === false)
            this.visibleSections.push(sectionId)
          return true
        }
      } else {
        if (this.options.filter(o => o.section === sectionId && o.fuoriserie === false && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
          if (this.visibleSections.includes(sectionId) === false)
            this.visibleSections.push(sectionId)
          return true
        }
      }
    } else {
      if (this.options.filter(o => o.section === sectionId && o.onlyImplicit === false && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0) {
        if (this.visibleSections.includes(sectionId) === false)
          this.visibleSections.push(sectionId)
        return true
      }
    }
    return false
  }

  /**
   * Returns if a given tab is visible (has elements)
   * @param tabId 
   * @returns 
   */
   isTabVisible(tabId: string): boolean {
    return this.options.filter(o => o.group === tabId && this.uiCommonService.applySearchFilter(o, this.inputText, this.modelCode)).length > 0
  }
  
  getOptionDataLinkTracking(menuLabel: string, tabId: string, sectionId: string, optionId: string, color: string, action: string): string {
    return this.mxeAnalyticsService.getOptionDataLinkTracking(menuLabel, tabId, sectionId, optionId, color, action)
  }

  private toElementData(o: UIOptItem): any {
    const domElem = document.getElementById(o.id)
    if(domElem){
      this.domOptionElements.push({
        elementIndex: -1,
        id: o.id,
        offTop: domElem.offsetTop,
        offHeight: domElem.offsetHeight,
      })
    }
  }
}