<div class="mxe__app-wrapper">
  <img class="logo-maserati" data-linktracking="top-nav:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />
  <button type="button" class="btn burger-menu"  data-linktracking ="side-nav:preferences:open" (click)="showHomeMenu()">
    <span class="bar1"></span>
    <span class="bar2"></span>
    <span class="bar3"></span>
  </button>
  <div class="streaming-mode-container" [ngClass]="{'disappear':disappearConnectionModeMessage}" *ngIf="showConnectionModeMessage">
    <div class="streaming-mode-infos">
      <img class="onpremise" src="../../../assets/icons/mxe-ico_on-premise.svg">
      <span class="text-infos">{{ getLabel('MXE_STREAMING_MODE_INFO','You are using') }}&nbsp;<strong>{{getLabel('MXE_STREAMING_MODE_ON_PREMISE_NAME', 'ON PREMISE MODE')}}</strong></span>
    </div>
  </div>

  <mxe-pairing-toggle
    [isMainScreen]="isMainScreen"
    [priceStatus]="priceStatus"
    [pairingStep]="PairingStepEnum.HOME"
    [labels]="labels"
    (screenCastActiveEmitter)="($event)"
    (mirroringStoppedByUserEmitter)="mirroringStoppedByUser = $event">
  </mxe-pairing-toggle>

  <!-- <div style="display: none;">
    <img *ngFor="let endpoint of endpoints; let i= index "
      src="{{endpoint}}/assets/garage.jpg"
      (load)="success(i)"
      (error)="failure(i)"
      height="0"
      width="0"
    />
  </div> -->

  <div class="mxe__details-container">
    <div class="cconfig-title-animated">
      <div class="bigtxt">MXE</div>
      <div class="smalltxt">CAR CONFIGURATOR</div>
    </div>
  </div>
  <div class="mxe__buttons-container">
   <!-- Per modifica su home aggiungere classe btn-accent al botton sotto -->
    <button type="button" class="btn btn-transparent btn-bordered"  data-linktracking ="cta:homepage:new-configuration" [routerLink]="['/mxe/clientname']"(click)="newConfiguration()">
      {{getLabel('MXE_NEW_CONFIGURATION', 'new configuration')}}
    </button>
    <!--<button type="button" class="btn btn-transparent btn-bordered"  data-linktracking ="cta:homepage:pre-defined-model" [routerLink]="['/mxe/machine-id']">
      Pre-Defined Model
    </button>-->
    <button type="button" class="btn btn-transparent btn-bordered" data-linktracking ="cta:homepage:load-configuration" [routerLink]="['/mxe/configuration']" (click)="loadConfiguration()">
      {{getLabel('MXE_LOAD_RESTORE_CONFIGURATION','LOAD/RESTORE CONFIGURATION')}}
    </button>
  </div>
</div>

<app-home-menu
  *ngIf="homeMenuIsOpen"
  (homeMenuStatusChange)="homeMenuStatusController($event)">
</app-home-menu>