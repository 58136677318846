export const appSettingsInitialState: AppState = {
    languageId: 'en',
    ctaLanguageId: 'it',
    countryCode: undefined,
    configId: '',
    showPrices: false,
    arePricesAvailable: false,
    ephemeralDeviceId: '',
    isScreenCastActive: false,
    envName: '',
    streamingAvailable: true,
    protocol: 'https:',
    host: '',
    hostName: '',
    applicationStep: ''
}

export interface AppState {
    languageId: string,
    countryCode: number | undefined,
    ctaLanguageId: string,
    currentCarModel?: string | number,
    configId: string,
    clientName?:string,
    clientSurname?:string,
    showPrices: boolean
    arePricesAvailable: boolean;
    ephemeralDeviceId: string;
    isScreenCastActive: boolean
    envName:string,
    streamingAvailable: boolean
    protocol: string
    host: string
    hostName: string
    applicationStep: string
}