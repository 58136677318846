import { ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { PAIRING_STEP_ENUM, STEP_ENUM } from 'src/app/core/models/common.model';
import { AuthService } from 'src/app/core/services/authentication.service';
import { UiCommonService } from 'src/app/core/services/ui-common.service';
import { RESET_CONFIGURATION_LOADED } from 'src/app/core/store/actions/configuration-actions';
import { UnrealDesiredSceneStateActions } from 'src/app/core/store/actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { MxeReducers } from 'src/app/core/store/reducers';
import { AutobahnClient } from '../../core/clients/autobahn.client';
import { Family } from 'src/app/core/models/get-models-service.model';
import { RESET_SELECTED_MODEL } from 'src/app/core/store/actions/models/models-actions';
import { RedirectService } from 'src/app/core/services/redirect.service';
import { ActivatedRoute } from '@angular/router';
import { IUserState } from 'src/app/core/store/initials/user-initial-state';
import { APP_SETTINGS_SET_APPLICATION_STEP } from 'src/app/core/store/actions/app-settings/app-settings-actions';
import { HandledError } from 'src/app/core/models/handled-error';


@Component({
  selector: 'mxe-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  @Input() isMainScreen: boolean = true

  priceStatus: boolean;
  families: Family[] = [];
  homeMenuIsOpen = false;
  PairingStepEnum = PAIRING_STEP_ENUM
  Step = STEP_ENUM
  mirroringStoppedByUser: boolean = false;
  labels: any
  ephemeralDeviceID: string;
  disappearConnectionModeMessage : boolean = false;
  showConnectionModeMessage : boolean = false;
  
  private languageId = 'en'
  private countryCode: number | undefined
  private screenCastActive: boolean = false;
  public addresses: string[] = []

  private tokenSub$: Subscription;
  private countryCode$: Subscription;
  private ephemeralDeviceID$: Subscription
  private screenCastActive$: Subscription
  private labels$: Subscription;
  private executionInformations$: Subscription
  private appExecutionInfos: { protocol: string, host: string,  hostName: string } = { protocol: 'https:', host: '', hostName: '' }
  public endpoints: string[] = [];
  private endpointsToTest: number = 0;
  private userState: IUserState
  constructor(
    private uiCommonService: UiCommonService,
    private store: Store<MxeReducers>,
    private authService: AuthService,
    private ngZone: NgZone,
    private autobahnClient: AutobahnClient,
    private chg: ChangeDetectorRef,
    private redirectService: RedirectService,
    private route: ActivatedRoute,
  ) {       this.store.dispatch(APP_SETTINGS_SET_APPLICATION_STEP({applicationStep: STEP_ENUM.HOME}))}

  ngOnInit() {
    if(this.isMainScreen) {
      this.disappearConnectionModeMessage = false;
      this.executionInformations$ = combineLatest([
        this.store.select(s => s.appState.protocol),
        this.store.select(s => s.appState.host),
        this.store.select(s => s.appState.hostName)
      ])
      .subscribe(
        ([protocol, host, hostName]) => {
          this.appExecutionInfos.protocol = protocol
          this.appExecutionInfos.host = host
          this.appExecutionInfos.hostName = hostName
          }
      );
      this.tokenSub$ = this.store.select((u) => u.userState).subscribe(
        userState => {
          this.userState = userState
          //if not authenticated
          if (!userState.jwt_token) {
            this.authService.userAuthentication();
          } else {
             if(this.appExecutionInfos.hostName.includes('local') && this.appExecutionInfos.host != 'localhost:8080'){
              this.showConnectionModeMessage = true;
             }
             this.authService.checkTokenExpiration(userState.jwt_token_expiration_time, userState.access_token_expiration_time)
            if(userState.dealerId){
              let selectedDealer = userState.dealersInfo.find(d => d.dealer_code == userState.dealerId)
              if(selectedDealer && selectedDealer.cloud_quota == 0 && selectedDealer.onprem_quota == 0 && !this.authService.wrongDealerConfigPopUpShown){
                this.authService.wrongDealerConfigPopUpShown = true;
                throw new HandledError(this.uiCommonService.getLabel('MXE_NOQUOTA', '', '', '', `MXE 3D immersive experience is not available due to incorrect settings of your Modis account. Please contact your dealer network country manager`))
              }
            }
          }
      });
      this.countryCode$ = this.store.select(s => s.appState.countryCode).subscribe(
        countryCode => this.countryCode = countryCode
      )
    
      this.store.dispatch(UnrealDesiredSceneStateActions.RESET_CONFIG_FOR_SCENE())
      this.store.dispatch(RESET_SELECTED_MODEL())

      this.screenCastActive$ = this.store.select(s => s.appState.isScreenCastActive).subscribe(
        screenCastActive => this.screenCastActive = screenCastActive)
      
      this.ephemeralDeviceID$ = this.store.select(s => s.appState.ephemeralDeviceId).subscribe(
        ephemeralDeviceID => this.ephemeralDeviceID = ephemeralDeviceID
      )

      this.labels$ = this.store.select(s => s.countryPropState.labels).subscribe(
        labels => {
          this.labels = labels
          if(this.screenCastActive && this.ephemeralDeviceID) {
            this.autobahnClient?.setLabels(this.ephemeralDeviceID, this.labels)
          }
        })
      this.initAdobeTracker();
      setTimeout(() => {
        this.disappearConnectionModeMessage = true;
      },2000)

      
    }
  }

  ngOnDestroy() {
    if(this.isMainScreen) {
      this.executionInformations$?.unsubscribe()
      this.ephemeralDeviceID$.unsubscribe()
      this.tokenSub$.unsubscribe()
      this.countryCode$.unsubscribe()
      this.screenCastActive$.unsubscribe()
      this.labels$.unsubscribe()
    }
  }

  /**
   * Reset the load configuration state
   */
  newConfiguration() {
    if(this.screenCastActive && this.ephemeralDeviceID)
      this.autobahnClient?.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.USER)
    this.ngZone.run(() => {
      this.store.dispatch(RESET_CONFIGURATION_LOADED())
    })
  }
  
  loadConfiguration() {
    if(this.screenCastActive && this.ephemeralDeviceID) {
      this.autobahnClient?.initializeLoadConfiguration(this.ephemeralDeviceID, true, false, '')
      .then(() => this.autobahnClient?.requestPairingFromSimplePage(this.ephemeralDeviceID, PAIRING_STEP_ENUM.CONFIGURATION_LOADER))
    }
  }
 
  showHomeMenu() {
    this.homeMenuIsOpen = true;
  }
  
  homeMenuStatusController(modalStatus) {
    this.homeMenuIsOpen = modalStatus;
    window.adobeDataLayer.push({
      "event": 'updatePageName',
      "data": {
      "pageInfo":
          { 
            "pageName": "homepage" 
          }
        }
      })
  }

  getLabel(optId: string, defaultValue: string) {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }
  
  onScreenCastActiveEmitter(value) {
    this.screenCastActive = value
  }

  private initAdobeTracker() {
    window.adobeDataLayer.push({
      "event": "genericPageLoad",
      "data": {
        "category": {
          "pageType": "shopping-tools"
        },
        "pageInfo": {
          "countryCode": `${this.countryCode}`,
          "language": `${this.languageId}`,
          "pageName": "homepage", 
          "vehicleBrand": "mxe",
          "tier": "tier-3"
        }
      }
    });
  }

}
