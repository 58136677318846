import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutobahnClient } from 'src/app/core/clients/autobahn.client';
import { UiCommonService } from 'src/app/core/services/ui-common.service';

@Component({
  selector: 'mxe-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss']
})
export class FullscreenLoaderComponent implements OnInit {
  @Output() loadingCompleted = new EventEmitter()
  @Output() refreshPageEvent = new EventEmitter()

  @Input() code: string;
  @Input() isMainScreen : boolean
  @Input() isWrapper : boolean
  @Input() screenCastActive
  @Input() ephemeralDeviceID: string

  imagePath: string = '';
  progress: number = 0;
  loadingDescription : string = ''
  loadingDescriptionState : boolean;  

  
  constructor(
    private chg: ChangeDetectorRef,
    private uiCommonService: UiCommonService,
    private autobahnClient: AutobahnClient,
  ) { }

  ngOnInit(): void {
    this.createImagePath();
    this.chg.detectChanges();
  }

  ngOnChanges(changes : SimpleChanges){
    if(!this.isMainScreen && changes['isWrapper'] && changes['isWrapper'].firstChange && changes['isWrapper'].currentValue == undefined ){
       this.autobahnClient.setIsWrapperFromLoader(this.ephemeralDeviceID,true);
    }

  }

  createImagePath(){

    const basePath = 'https://configurator-test.maserati.com/ccbe/public/api/photocopier';
    const tmpBasePath = '../assets/loader-cars/'
    const country = 104;

    //this.imagePath = basePath+'?country='+country+'&modelId='+this.code+'&view=mainExtRear&scaleX=1920&cropY=1080'

    this.imagePath = tmpBasePath+this.code+'.png';
    return this.imagePath

  }

  getLabelDefValue(optId: string, defaultValue: string = '') {
    return this.uiCommonService.getLabel(optId, '', '', '', defaultValue)
  }

  progressBarIncrement(value: number){

      if(this.progress <100){
        if(this.isWrapper){
          const loadingTime = 500;
          this.progress = this.progress + value / (loadingTime / 100);
            if(this.progress > 0 && this.progress <= 8){
              this.loadingDescriptionState = true;
              this.loadingDescription = "Loading your MXE experience"
            }
            else if(this.progress > 8 && this.progress <= 10){
              this.loadingDescriptionState = false;
            }
            else if(this.progress > 10 && this.progress <= 18){
              this.loadingDescriptionState = true;
              this.loadingDescription = "Loading Interface"
            } 
            else if(this.progress > 18 && this.progress <= 20){
              this.loadingDescriptionState = false;
            }
            else if(this.progress > 20 && this.progress <= 38){
              this.loadingDescriptionState = true;
              this.loadingDescription = "Loading model's optionals"
            }
            else if(this.progress > 38 && this.progress <= 40){
              this.loadingDescriptionState = false;
            }
            else if(this.progress > 40 && this.progress <= 78){
              this.loadingDescriptionState = true;
              this.loadingDescription = "Loading Translations and Actions"
            }
            else if(this.progress > 78 && this.progress <= 80){
              this.loadingDescriptionState = false;
            }
            else if(this.progress > 80 && this.progress <= 90){
              this.loadingDescriptionState = true;
              this.loadingDescription = "Connecting to streaming service"
            }
            else if(this.progress > 90 && this.progress <= 92){
              this.loadingDescriptionState = false;
            }
            else if(this.progress > 92 && this.progress <= 100){
              this.loadingDescriptionState = true;
              this.loadingDescription = "Opening the car configurator"
            }
          }
          else{
            this.progress = this.progress + value
          }
          this.chg.detectChanges();
      }else{
        this.loadingCompleted.emit()
      }
    
  }

  refreshPage(){
    this.refreshPageEvent.emit()
  }
}
