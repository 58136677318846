<div class="mxe__home-menu-wrapper">
  <!--<img class="logo-maserati" data-linktracking="side-nav:configurator:logo" src="../../../assets/logo-maserati-icon.svg" alt="Maserati" />-->
  <button type="button" class="btn btn-x" (click)="closeTrimModal()" data-linktracking="side-nav:configurator:close">
    <img src="../../../assets/icons/mxe__ico-exit-menu.svg" alt="X" />
  </button>
  <div class="mxe__menu-items-container gradient-line-container">
    <button
      type="button"
      class="btn btn-transparent gradient-line"
      [routerLink]="['/mxe']"
      (click)="goToHomePage()"
      data-linktracking="side-nav:configurator:homepage">
      <img class="icon-trident-menu"  src="../../../assets/icons/mxe_ico-menu-home.svg" alt="country" />
      {{getLabel('MXE_HOMEPAGE_MENU_LABEL')}}
    </button>
    <button
      type="button"
      class="btn btn-transparent gradient-line"
      [routerLink]="['/mxe/models']"
      (click)="goToModels()"
      data-linktracking="side-nav:configurator:change-carline">
      <img class="icon-trident-menu"  src="../../../assets/icons/mxe_ico-menu-change-model.svg" alt="country" />
      {{getLabel('CHANGE_MODEL_DIALOG_TITLE')}}
    </button>
    <button
      type="button"
      class="btn btn-transparent gradient-line"
      [routerLink]="['/mxe/models',currentFamily.model]"
      (click)="goToTrims()"
      data-linktracking="side-nav:configurator:change-trim">
      <img class="icon-trident-menu"  src="../../../assets/icons/mxe_ico-menu-change-trim.svg" alt="country" />
      {{getLabel('MXE_CHANGE_TRIM_MENU_LABEL')}}
    </button>
  </div>
</div>