<div class="mxe__loader-poster" *ngIf="progress < 100">
<div class="mxe__fullscreen-loader">
    <div class="mxe__title-animation-container">
        <div class="cconfig-title-animated">
          <div class="bigtxt">MXE</div>
          <div class="smalltxt">CAR CONFIGURATOR</div>
        </div>
      </div>
    <div class="mxe__loader-bar">
        <h2 *ngIf="!isWrapper">
            Loading your <br />
            MXE experience — <span>{{progress}}%</span>
        </h2>
        <div class="loader-container" *ngIf="isWrapper">
            <div class="status" >
                <h2 class="status-text" 
                [ngClass]="{'fade-out': !loadingDescriptionState}">{{loadingDescription}}</h2>
            </div>
            <div class="progress">
                <h2 class="progress-text">{{progress}}%</h2>
            </div>
            
        </div>

        <div class="loading-bar-container">
            <div class="progressive" [style.width.%]="progress"></div>
        </div>
    </div>
    <button *ngIf="isWrapper && isMainScreen" class="btn btn-trasparent refresh-button" (click)="refreshPage()">
        <span class="refresh-button-text">{{getLabelDefValue('REFRESH','Refresh')}}</span>
        <img class="refresh-img" src="../../../assets/icons/mxe-ico-refresh.svg">
    </button>
</div>
</div>